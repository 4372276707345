import React, { lazy } from 'react';
import { Link, useParams } from 'react-router-dom';
import SearchContainer from '../../Components/SearchContainer';
import CustomIcon from '../../Constants/Icons';

const Listings = lazy(() => import('./Listings'));
const Sales = lazy(() => import('./Sales'));
const Settlements = lazy(() => import('./Settlements'));
const UncondBonus = lazy(() => import('./Uncond-Bonus'));

const MENU = [
  { title: 'Listings', icon: 'home', path: 'listings' },
  { title: 'Sales', icon: 'invoice', path: 'sales' },
  { title: 'Settlements', icon: 'calendar', path: 'settlements' },
  { title: 'Uncond. Bonus', icon: 'user', path: 'uncond-bonus' }
];

const ParamsMap = {
  listings: { title: 'Listings', Component: Listings },
  sales: { title: 'Sales', Component: Sales },
  settlements: { title: 'Settlements', Component: Settlements },
  'uncond-bonus': { title: 'Unconditional Bonus', Component: UncondBonus }
};

function Results() {
  let urlParams = useParams();
  if (urlParams?.sub_result) {
    const { title, Component } = ParamsMap[urlParams.sub_result];
    return (
      <div>
        <SearchContainer title={title} />
        <Component />
      </div>
    );
  } else {
    return (
      <div className="result-page-wrapper">
        <SearchContainer title="Results" />
        <div className="d-flex justify-content-center align-items-center">
          <div className="home-menu-container mt-3">
            {MENU.map((item, key) => (
              <Link to={item.path} className="nav-link" key={key}>
                <div className="menu-items">
                  <div>
                    <CustomIcon icon={item.icon} className="menu-icon" color="#555555" />
                  </div>
                  <div className="menu-title">{item.title}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Results;
