import * as types from './actionType';

export const showModal = (data) => ({
  type: types.SHOW_MODAL,
  payload: data
});

export const hideModal = (data) => ({
  type: types.HIDE_MODAL,
  payload: data
});
