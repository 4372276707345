import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  // BASE_PATH_PREFIX,
  LOGIN_PATH
  // VERFICATION_PATH
} from '../Constants/routes';
import { FranchiseReports } from '../Containers/FranchiseReports';
import LeaderShipPairing from '../Containers/LeaderShip';
import { RecruitExams } from '../Containers/RecruitExams';
import Results from '../Containers/Results';
import SuppportMaterial from '../Containers/SupportMaterial';

const App = lazy(() => import('../App'));
const Home = lazy(() => import('../Containers/Home'));
const Events = lazy(() => import('../Containers/Events'));
const Resources = lazy(() => import('../Containers/Resources'));
const Organisations = lazy(() => import('../Containers/Organisations'));
const Users = lazy(() => import('../Containers/Users'));
// const Verfication = lazy(() => import('../Containers/Verfication'));

function PrivateRoute({ isLogged, ...rest }) {
  let location = useLocation();
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  const renderComponent = () => {
    if (isLogged) return <App {...rest} />;
    // if (verificationData && location.pathname === VERFICATION_PATH)
    //   return <Verfication />
    // else
    else return <Navigate to={LOGIN_PATH} state={{ from: location }} />;
  };

  return (
    <Routes>
      <Route {...rest} element={renderComponent()}>
        <Route index element={<Home />} />
        <Route path="events" element={<Events />} />
        <Route path="resources" element={<Resources />} />
        <Route path="organisations" element={<Organisations />} />
        <Route path="users" element={<Users />} />
        <Route path="leaderShip" element={<LeaderShipPairing />} />
        <Route path="recruitExams" element={<RecruitExams />} />
        <Route path="FranchiseReport" element={<FranchiseReports />} />
        {/* <Route path="verification" element={
          isLogged ? <Navigate to={BASE_PATH_PREFIX} /> : <Verfication />
        } /> */}
        <Route path="results">
          <Route index element={<Results />} />
          <Route path=":sub_result" element={<Results />} />
        </Route>
        <Route path="supportMaterial">
          <Route index element={<SuppportMaterial />} />
          <Route path=":sub_page" element={<SuppportMaterial />} />
        </Route>
      </Route>
    </Routes>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    isLogged: !!auth.authData,
    verificationData: auth.verificationData
  };
};

export default connect(mapStateToProps)(PrivateRoute);
