export const GET_LEADERSHIP_RATING_REQUEST = 'GET_LEADERSHIP_RATING_REQUEST ';
export const GET_LEADERSHIP_RATING_SUCCESS = 'GET_LEADERSHIP_RATING_SUCCESS ';
export const GET_LEADERSHIP_RATING_FAILURE = 'GET_LEADERSHIP_RATING_FAILURE ';

export const GET_TOP_LEADER_REQUEST = 'GET_TOP_LEADER_REQUEST ';
export const GET_TOP_LEADER_SUCCESS = 'GET_TOP_LEADER_SUCCESS ';
export const GET_TOP_LEADER_FAILURE = 'GET_TOP_LEADER_FAILURE ';

export const GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST =
  'GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST';
export const GET_LEADERSHIP_SETTINGS_ORGANISATION_SUCCESS =
  'GET_LEADERSHIP_SETTINGS_ORGANISATION_SUCCESS';
export const GET_LEADERSHIP_SETTINGS_ORGANISATION_FAILURE =
  'GET_LEADERSHIP_SETTINGS_ORGANISATION_FAILURE';

export const GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST = 'GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST';
export const GET_LEADERSHIP_SETTINGS_LEADERS_SUCCESS = 'GET_LEADERSHIP_SETTINGS_LEADERS_SUCCESS';
export const GET_LEADERSHIP_SETTINGS_LEADERS_FAILURE = 'GET_LEADERSHIP_SETTINGS_LEADERS_FAILURE';

//This is for leadership ratings settings??

export const GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST =
  'GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST';
export const GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_SUCCESS =
  'GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_SUCCESS';
export const GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_FAILURE =
  'GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_FAILURE';

export const GET_LEADERSHIP_SETTINGS_REQUEST = 'GET_LEADERSHIP_SETTINGS_REQUEST';
export const GET_LEADERSHIP_SETTINGS_SUCCESS = 'GET_LEADERSHIP_SETTINGS_SUCCESS';
export const GET_LEADERSHIP_SETTINGS_FAILURE = 'GET_LEADERSHIP_SETTINGS_FAILURE';

export const GET_AVAILABLE_LEADERSHIP_REQUEST = 'GET_AVAILABLE_LEADERSHIP_REQUEST';
export const GET_AVAILABLE_LEADERSHIP_SUCCESS = 'GET_AVAILABLE_LEADERSHIP_SUCCESS';
export const GET_AVAILABLE_LEADERSHIP_FAILURE = 'GET_AVAILABLE_LEADERSHIP_FAILURE';
