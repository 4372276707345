import * as types from './actionType';

function leadershipReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_LEADERSHIP_RATING_REQUEST:
      return {
        ...state,
        ...payload,
        leaderRatingLoading: true
      };
    case types.GET_LEADERSHIP_RATING_SUCCESS:
      return {
        ...state,
        leaderShipRatingData: payload?.message,
        leaderRatingLoading: false
      };
    case types.GET_LEADERSHIP_RATING_FAILURE:
      return {
        ...state,
        ...payload,
        leaderRatingLoading: false
      };
    case types.GET_TOP_LEADER_REQUEST:
      return {
        ...state,
        leaderShipLoading: true
      };
    case types.GET_TOP_LEADER_SUCCESS:
      return {
        ...state,
        topLeader: payload?.data,
        leaderShipLoading: false
      };
    case types.GET_TOP_LEADER_FAILURE:
      return {
        ...state,
        leaderShipLoading: false
      };

    case types.GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST:
      return {
        ...state,
        ...payload,
        leadershipOrganisationLoading: true
      };
    case types.GET_LEADERSHIP_SETTINGS_ORGANISATION_SUCCESS:
      return {
        ...state,
        leadershipOrganisationData: payload.data,
        leadershipOrganisationLoading: false
      };
    case types.GET_LEADERSHIP_SETTINGS_ORGANISATION_FAILURE:
      return {
        ...state,
        ...payload,
        leadershipOrganisationLoading: false
      };

    case types.GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST:
      return {
        ...state,
        leadershipLeadersLoading: true
      };
    case types.GET_LEADERSHIP_SETTINGS_LEADERS_SUCCESS:
      return {
        ...state,
        leadershipLeadersData: payload?.message?.data,
        leadershipLeadersLoading: false
      };
    case types.GET_LEADERSHIP_SETTINGS_LEADERS_FAILURE:
      return {
        ...state,
        leadershipLeadersLoading: false
      };

    case types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST:
      return {
        ...state,
        leadershipLeaderRatingsSettingsLoading: true
      };
    case types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_SUCCESS:
      return {
        ...state,
        leadershipLeaderRatingsSettingsData: payload.data,
        leadershipLeaderRatingsSettingsLoading: false
      };
    case types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_FAILURE:
      return {
        ...state,
        leadershipLeaderRatingsSettingsLoading: false
      };

    case types.GET_LEADERSHIP_SETTINGS_REQUEST:
      return {
        ...state,
        leadershipSettingsTableData: [],
        leaderSettingLoading: true
      };
    case types.GET_LEADERSHIP_SETTINGS_SUCCESS:
      return {
        ...state,
        leadershipSettingsTableData: payload?.message?.data,
        leaderSettingLoading: false
      };
    case types.GET_LEADERSHIP_SETTINGS_FAILURE:
      return {
        ...state,
        leaderSettingLoading: false
      };
    case types.GET_AVAILABLE_LEADERSHIP_REQUEST:
      return {
        ...state
      };
    case types.GET_AVAILABLE_LEADERSHIP_SUCCESS:
      return {
        ...state,
        avaialbleLeaders: payload?.message?.data
      };
    case types.GET_AVAILABLE_LEADERSHIP_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}

export default leadershipReducer;
