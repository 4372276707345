import React from 'react';
import { connect } from 'react-redux';
import { QuickSearch } from '../../Components/FormFields/QuickSearch';
import CustomTable from '../../Components/Table';
import { dynamicColorChanger } from '../../Constants/utils';
import { DynamicMonthChanger } from '../LeaderShip/DynmaicMonthChanger';
const tempData = [
  {
    date: '10-01-2022',
    name: 'user1',
    office: 'sampleOffice1',
    result: 'Passed',
    marksObtained: '3'
  },

  {
    date: '14-01-2022',
    name: 'user2',
    office: 'sampleOffice3',
    result: 'Failed',
    marksObtained: '0'
  },
  {
    date: '18-01-2022',
    name: 'user3',
    office: 'sampleOffice4',
    result: 'Passed',
    marksObtained: '4'
  },
  {
    date: '22-01-2022',
    name: 'user4',
    office: 'sampleOffice4',
    result: 'Pending',
    marksObtained: '0'
  }
];
export const RecruitmentContainer = () => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Office',
      dataIndex: 'office',
      key: 'office'
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      render: (title, data) => {
        let finalResult = data.result;
        return (
          <>
            <div>
              <div
                className="font-weight-bold"
                style={{ color: `${dynamicColorChanger(finalResult)}` }}>
                {finalResult === 'Passed'
                  ? `${finalResult} (${data?.marksObtained}/5)`
                  : finalResult}
              </div>
            </div>
          </>
        );
      }
    }
  ];
  return (
    <div>
      <div className="d-flex justify-content-center align-item-center">
        <div className="content-section">
          <div className="d-flex justify-content-between pt-4">
            <div>
              <DynamicMonthChanger month={true} />
            </div>
            <QuickSearch />
          </div>
          <div className="mt-3">
            <CustomTable columns={columns} dataSource={tempData} scroll={{ y: 500 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentContainer);
