import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '.';

export function getRandomInt(min = 1, max = 1000) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * max + min); //The maximum is exclusive and the minimum is inclusive
}

export function formatDate(
  date,
  existingFormat = DEFAULT_DATE_FORMAT,
  newFormat = DEFAULT_DATE_FORMAT
) {
  try {
    return moment(date, existingFormat).format(newFormat);
  } catch (error) {
    return moment().format();
  }
}

export function staticBackground(min = 1, max = 5) {
  return (
    'https://v2-wiseberryonline-com-au.s3-ap-southeast-2.amazonaws.com/assets/log-in-page/' +
    getRandomInt(min, max) +
    '.jpg'
  );
}

export const downloadImageFromUrl = (url) => {
  fetch(url)
    .then((res) => {
      res.blob().then(function (blob) {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', url.split('/')?.at(-1) ?? 'image.png');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      });
    })
    .catch(() => {});
};

export const formatBytes = async (bytes, decimals = 2) => {
  if (bytes === 0) return '0B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const basicRequired = [
  {
    required: true,
    message: 'Required Field'
  }
];

export function dynamicColorChanger(title) {
  switch (title) {
    case 'Passed':
      return 'green';
    case 'Pending':
      return 'orange';
    case 'Failed':
      return 'red';
    default:
      return '';
  }
}
