import * as types from './actionType';

function organisationReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_ORGANISATION_REQUEST:
      return {
        ...state,
        getOrganisationLoading: true
      };
    case types.GET_ALL_ORGANISATION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { ...rest } = payload;
      // eslint-disable-next-line no-case-declarations
      const tempPayload = { ...rest };
      return {
        ...state,
        getOrganisationLoading: false,
        ...tempPayload
      };
    case types.GET_ALL_ORGANISATION_FAILURE:
      return {
        ...state,
        getOrganisationLoading: false
      };
    case types.CREATE_ORGANISATION_REQUEST:
      return {
        ...state,
        createOrganisationLoading: true
      };
    case types.CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        createOrganisationLoading: false,
        createOrganisationsList: payload.data
      };
    case types.CREATE_ORGANISATION_FAILURE:
      return {
        ...state,
        createOrganisationLoading: false
      };
    case types.POST_STATUS_UPDATE_REQUEST:
      return {
        ...state
      };
    case types.POST_STATUS_UPDATE_SUCCESS:
      return {
        ...state
      };
    case types.POST_STATUS_UPDATE_FAILURE:
      return {
        ...state
      };
    case types.POST_WEBSITE_UPDATE_REQUEST:
      return {
        ...state
      };
    case types.POST_WEBSITE_UPDATE_SUCCESS:
      return {
        ...state
      };
    case types.POST_WEBSITE_UPDATE_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
}

export default organisationReducer;
