import { Input, Tooltip, Form } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import CustomIcon from '../../Constants/Icons';

export const QuickSearch = ({
  label,
  name,
  size = 'small',
  className,
  disabled = false,
  style,
  showDic = true,
  ...rest
}) => {
  return (
    <div>
      <div>
        <Form.Item
          label={label}
          name={name}
          className={`${className} input-content height`}
          style={{ ...style }}>
          <Input
            size={size}
            suffix={
              showDic && (
                <Tooltip title="Speak here">
                  <CustomIcon icon="mic" onClick={() => {}} />
                </Tooltip>
              )
            }
            placeholder="Quick search"
            disabled={disabled}
            className="input-label"
            {...rest}
          />
        </Form.Item>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(QuickSearch);
