import React from 'react';
import { Form, Select } from 'antd';
import _map from 'lodash/map';

const { Option } = Select;

export const SelectField = ({
  name,
  label,
  rules = [],
  placeholder,
  className = '',
  onChange,
  dataSource = [],
  size = 'small',
  mode = '',
  ...rest
}) => {
  return (
    <div className="form-select-field">
      <div className="form-select-label">{label}</div>
      <Form.Item name={name} className={className} rules={rules}>
        <Select
          mode={mode}
          size={size}
          style={{ width: '100%' }}
          placeholder={placeholder}
          className="select_content"
          onChange={onChange}
          {...rest}>
          {_map(dataSource, (data, key) => {
            return (
              <Option value={data.value} key={key} disabled={data && data.disabled}>
                <span dangerouslySetInnerHTML={{ __html: data.label }}></span>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};
