import { fileUpload, post } from '../request';

export const getAllCategories = (data) => post('parent_category', data);
export const addCategory = (data) => fileUpload('add_category', data);
export const getSingleCategory = (data) => post('get_category', data);
export const editCategory = (data) => fileUpload('update_category', data);
export const deleteCategory = (data) => post('delete_category', data);

export const getAllSubCategories = (data) => post('get_subcategories', data);
export const addSubCategory = (data) => fileUpload('add_subcategory', data);
export const getSingleSubCategory = (data) => post('get_subcategory', data);
export const editSubCategory = (data) => fileUpload('update_subcategory', data);
export const deleteSubCategory = (data) => post('delete_subcategory', data);

export const getAllItems = (data) => post('get_items', data);
export const addItem = (data) => fileUpload('add_item', data);
export const getSingleItem = (data) => post('get_item', data);
export const editItem = (data) => fileUpload('update_item', data);
export const deleteItem = (data) => post('delete_item', data);
