import { call, put, takeLatest } from 'redux-saga/effects';

import openNotification from '../../Components/Notification';
import {
  addCategoryFailure,
  addCategorySuccess,
  deleteCategoryFailure,
  deleteCategorySuccess,
  editCategorySuccess,
  getAllCategoriesFailure,
  getAllCategoriesRequest,
  getAllCategoriesSuccess,
  getAllItemsFailure,
  getAllItemsSuccess,
  getAllSubCategoriesFailure,
  getAllSubCategoriesSuccess,
  getSingleCategoryFailure,
  getSingleCategorySuccess,
  updateResourceReducer,
  getSingleSubCategorySuccess,
  getSingleSubCategoryFailure,
  getAllSubCategoriesRequest,
  addSubCategorySuccess,
  addSubCategoryFailure,
  editSubCategorySuccess,
  editSubCategoryFailure,
  deleteSubCategorySuccess,
  deleteSubCategoryFailure,
  getSingleItemSuccess,
  getSingleItemFailure,
  getAllItemsRequest,
  addItemSuccess,
  addItemFailure,
  editItemSuccess,
  editItemFailure,
  deleteItemSuccess,
  deleteItemFailure
} from './actions';

import {
  ADD_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  EDIT_CATEGORY_REQUEST,
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_ITEMS_REQUEST,
  GET_ALL_SUB_CATEGORIES_REQUEST,
  GET_SINGLE_CATEGORY_REQUEST,
  GET_SINGLE_SUB_CATEGORY_REQUEST,
  ADD_SUB_CATEGORY_REQUEST,
  EDIT_SUB_CATEGORY_REQUEST,
  DELETE_SUB_CATEGORY_REQUEST,
  GET_SINGLE_ITEM_REQUEST,
  ADD_ITEM_REQUEST,
  EDIT_ITEM_REQUEST,
  DELETE_ITEM_REQUEST
} from './actionType';
import {
  addCategory,
  deleteCategory,
  editCategory,
  getAllCategories,
  getAllItems,
  getAllSubCategories,
  getSingleCategory,
  getSingleSubCategory,
  addSubCategory,
  editSubCategory,
  deleteSubCategory,
  addItem,
  getSingleItem,
  editItem,
  deleteItem
} from './services';
import { hideModal } from '../modal/actions';
import { logOutSuccess } from '../auth/actions';

function* getAllCategorySaga(action) {
  try {
    const { response } = yield call(getAllCategories, action.payload);
    if (response?.status) {
      yield put(getAllCategoriesSuccess(response.data?.data));
    } else {
      yield put(logOutSuccess());
      openNotification('error', response.message);
      yield put(getAllCategoriesFailure());
    }
  } catch (err) {
    yield put(getAllCategoriesFailure());
  }
}

function* addCategorySaga(action) {
  try {
    const { response } = yield call(addCategory, action.payload);
    if (response?.status) {
      yield put(addCategorySuccess());
      yield put(hideModal());
      yield put(getAllCategoriesRequest({ user_id: action.payload.user_id, page: 1 }));
    } else {
      openNotification('error', response.message);
      yield put(addCategoryFailure());
    }
  } catch (err) {
    yield put(addCategoryFailure());
  }
}

function* getSingleCategorySaga(action) {
  try {
    const { response } = yield call(getSingleCategory, action.payload);
    if (response?.status) {
      yield put(getSingleCategorySuccess(response.data));
    } else {
      openNotification('error', response.message);
      yield put(getSingleCategoryFailure());
    }
  } catch (err) {
    yield put(getSingleCategoryFailure());
  }
}

function* editCategorySaga(action) {
  try {
    const { response } = yield call(editCategory, action.payload);
    if (response?.status) {
      yield put(editCategorySuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ category: {} }));
      yield put(getAllCategoriesRequest({ user_id: action.payload.user_id, page: 1 }));
    } else {
      openNotification('error', response.message);
      yield put(addCategoryFailure());
    }
  } catch (err) {
    yield put(addCategoryFailure());
  }
}

function* deleteCategorySaga(action) {
  try {
    const { response } = yield call(deleteCategory, action.payload);
    if (response?.status) {
      yield put(deleteCategorySuccess());
      yield put(addSubCategorySuccess());
      yield put(getAllSubCategoriesSuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ category: {} }));
      yield put(getAllCategoriesRequest({ user_id: action.payload.user_id, page: 1 }));
      yield put(getAllItemsSuccess());
    } else {
      openNotification('error', response.message);
      yield put(deleteCategoryFailure());
    }
  } catch (err) {
    yield put(deleteCategoryFailure());
  }
}

function* getAllSubCategorySaga(action) {
  try {
    const { response } = yield call(getAllSubCategories, action.payload);
    if (response?.status) {
      yield put(getAllSubCategoriesSuccess(response.data));
      yield put(getAllItemsSuccess());
    } else {
      yield put(getAllItemsSuccess());
      yield put(getAllSubCategoriesFailure());
    }
  } catch (err) {
    yield put(getAllSubCategoriesFailure());
  }
}

function* addSubCategorySaga(action) {
  try {
    const { response } = yield call(addSubCategory, action.payload);
    if (response?.status) {
      yield put(addSubCategorySuccess());
      yield put(hideModal());
      yield put(
        getAllSubCategoriesRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_fk
        })
      );
    } else {
      openNotification('error', response.message);
      yield put(addSubCategoryFailure());
    }
  } catch (err) {
    yield put(addSubCategoryFailure());
  }
}

function* getSingleSubCategorySaga(action) {
  try {
    const { response } = yield call(getSingleSubCategory, action.payload);
    if (response?.status) {
      yield put(getSingleSubCategorySuccess(response.data));
    } else {
      openNotification('error', response.message);
      yield put(getSingleSubCategoryFailure());
    }
  } catch (err) {
    yield put(getSingleSubCategoryFailure());
  }
}

function* editSubCategorySaga(action) {
  try {
    const { response } = yield call(editSubCategory, action.payload);
    if (response?.status) {
      yield put(editSubCategorySuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ subCategory: {} }));
      yield put(
        getAllSubCategoriesRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_fk
        })
      );
    } else {
      openNotification('error', response.message);
      yield put(editSubCategoryFailure());
    }
  } catch (err) {
    yield put(editSubCategoryFailure());
  }
}

function* deleteSubCategorySaga(action) {
  try {
    const { response } = yield call(deleteSubCategory, action.payload);
    if (response?.status) {
      yield put(deleteSubCategorySuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ subCategory: {} }));
      yield put(
        getAllSubCategoriesRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_id
        })
      );
      yield put(getAllItemsSuccess(response.data));
    } else {
      openNotification('error', response.message);
      yield put(deleteSubCategoryFailure());
    }
  } catch (err) {
    yield put(deleteSubCategoryFailure());
  }
}

function* getAllItemSaga(action) {
  try {
    const { response } = yield call(getAllItems, action.payload);
    if (response?.status) {
      yield put(getAllItemsSuccess(response.data));
    } else {
      openNotification('error', response.message);
      yield put(getAllItemsFailure());
    }
  } catch (err) {
    yield put(getAllItemsFailure());
  }
}

function* addItemSaga(action) {
  try {
    const { response } = yield call(addItem, action.payload);
    if (response?.status) {
      yield put(addItemSuccess());
      yield put(hideModal());
      yield put(
        getAllItemsRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_fk,
          subcategory_fk: action.payload.subcategory_fk
        })
      );
    } else {
      openNotification('error', response.message);
      yield put(addItemFailure());
    }
  } catch (err) {
    yield put(addItemFailure());
  }
}

function* getSingleItemSaga(action) {
  try {
    const { response } = yield call(getSingleItem, action.payload);
    if (response?.status) {
      yield put(getSingleItemSuccess(response.data));
    } else {
      openNotification('error', response.message);
      yield put(getSingleItemFailure());
    }
  } catch (err) {
    yield put(getSingleItemFailure());
  }
}

function* editItemSaga(action) {
  try {
    const { response } = yield call(editItem, action.payload);
    if (response?.status) {
      yield put(editItemSuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ subItem: {} }));
      yield put(
        getAllItemsRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_fk,
          subcategory_fk: action.payload.subcategory_fk
        })
      );
    } else {
      openNotification('error', response.message);
      yield put(editItemFailure());
    }
  } catch (err) {
    yield put(editItemFailure());
  }
}

function* deleteItemSaga(action) {
  try {
    const { response } = yield call(deleteItem, action.payload);
    if (response?.status) {
      yield put(deleteItemSuccess());
      yield put(hideModal());
      yield put(updateResourceReducer({ subItem: {} }));
      yield put(
        getAllItemsRequest({
          user_id: action.payload.user_id,
          category_id: action.payload.category_fk,
          subcategory_fk: action.payload.subcategory_fk
        })
      );
    } else {
      openNotification('error', response.message);
      yield put(deleteItemFailure());
    }
  } catch (err) {
    yield put(deleteItemFailure());
  }
}

export default function* watchAuth() {
  yield takeLatest(GET_ALL_CATEGORIES_REQUEST, getAllCategorySaga);
  yield takeLatest(ADD_CATEGORY_REQUEST, addCategorySaga);
  yield takeLatest(GET_SINGLE_CATEGORY_REQUEST, getSingleCategorySaga);
  yield takeLatest(EDIT_CATEGORY_REQUEST, editCategorySaga);
  yield takeLatest(DELETE_CATEGORY_REQUEST, deleteCategorySaga);
  yield takeLatest(GET_ALL_SUB_CATEGORIES_REQUEST, getAllSubCategorySaga);
  yield takeLatest(ADD_SUB_CATEGORY_REQUEST, addSubCategorySaga);
  yield takeLatest(GET_SINGLE_SUB_CATEGORY_REQUEST, getSingleSubCategorySaga);
  yield takeLatest(EDIT_SUB_CATEGORY_REQUEST, editSubCategorySaga);
  yield takeLatest(DELETE_SUB_CATEGORY_REQUEST, deleteSubCategorySaga);
  yield takeLatest(GET_ALL_ITEMS_REQUEST, getAllItemSaga);
  yield takeLatest(ADD_ITEM_REQUEST, addItemSaga);
  yield takeLatest(GET_SINGLE_ITEM_REQUEST, getSingleItemSaga);
  yield takeLatest(EDIT_ITEM_REQUEST, editItemSaga);
  yield takeLatest(DELETE_ITEM_REQUEST, deleteItemSaga);
}
