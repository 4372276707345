import React from 'react';
import { connect } from 'react-redux';
import SearchContainer from '../../Components/SearchContainer';
import { RecruitmentContainer } from './RecruitmentContainer';

export const RecruitExams = () => {
  return (
    <div id="recruitmentList">
      <SearchContainer title="Recruit Exams" />
      <RecruitmentContainer />
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(RecruitExams);
