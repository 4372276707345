import { post } from '../request';

export const getAllEvents = (data) => post('get_event', data);
export const getSingleEvents = (data) => post('edit_event', data);
export const addEvent = (data) => post('add_event', data);
export const editEvent = (data) => post('update_event', data);
export const deleteEvent = (data) => post('delete_event', data);
export const deleteEventAttendee = (data) => post('delete_event_attendees', data);
export const postAttendee = () => ({ response: { status: true } });
export const getAttendee = () => ({ response: { status: true } });
export const getMembers = (data) => post('search_member', data);
export const registerUpdatedEvent = (data) =>
  post('https://resource.wiseberryonline.com.au/backend/api/registerupdate_event', data);
