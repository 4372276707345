import { call, put, takeLatest } from '@redux-saga/core/effects';
import openNotification from '../../Components/Notification';

import {
  getAllOrganisationsFailure,
  getAllOrganisationsSuccess,
  createOrganisationsSuccess,
  createOrganisationsFailure,
  postStatusUpdateSuccess,
  postStatusUpdateFailure,
  getAllOrganisationsRequest,
  postWebsiteUpdateSuccess,
  postWebsiteUpdateFailure
} from './actions';
import {
  GET_ALL_ORGANISATION_REQUEST,
  CREATE_ORGANISATION_REQUEST,
  POST_STATUS_UPDATE_REQUEST,
  POST_WEBSITE_UPDATE_REQUEST
} from './actionType';
import {
  getAllOrganisation,
  createNewOrganisation,
  organisationStatusUpdateService,
  postWebsiteUpdateService
} from './sevices';

import { logOutSuccess } from '../auth/actions';

function* getAllOrganisationSaga(action) {
  try {
    const { response } = yield call(getAllOrganisation, action.payload);
    if (response.status) {
      let result = {
        active: action?.payload?.active
      };
      if (action?.payload?.active === 1) {
        result.organisationsList = response?.message?.data;
        result.futurePage = action?.payload?.page;
        result.others = response?.message;
      } else {
        result.inactiveorganisationsList = response?.message?.data;
        result.inactiveFuturePage = action?.payload?.page;
        result.inactiveNextPage = response?.message?.next_page_url;
      }
      yield put(getAllOrganisationsSuccess(result));
    } else {
      yield put(logOutSuccess());
      openNotification('error', response.message);
      yield put(getAllOrganisationsFailure());
    }
  } catch (error) {
    yield put(getAllOrganisationsFailure());
  }
}
function* createOrganisationSaga(action) {
  try {
    const { response } = yield call(createNewOrganisation, action.payload);
    if (response.status) {
      yield put(createOrganisationsSuccess(response.message));
    } else {
      yield put(createOrganisationsFailure());
    }
  } catch (error) {
    yield put(createOrganisationsFailure());
  }
}
function* postStatusUpdate(action) {
  try {
    const { response } = yield call(organisationStatusUpdateService, action.payload);
    if (response?.status) {
      yield put(postStatusUpdateSuccess(response));
      yield put(
        getAllOrganisationsRequest({
          user_id: action?.payload?.user_id,
          password: 'WOL06209',
          username: 'wol',
          page: 1
        })
      );
    } else {
      yield put(postStatusUpdateFailure());
    }
  } catch (error) {
    yield put(postStatusUpdateFailure());
  }
}
function* postWebsiteSaga(action) {
  try {
    const { response } = yield call(postWebsiteUpdateService, action.payload);
    if (response?.status) {
      yield put(postWebsiteUpdateSuccess(response));
    } else {
      yield put(postWebsiteUpdateFailure());
    }
  } catch (error) {
    yield put(postWebsiteUpdateFailure());
  }
}
export default function* watchEvent() {
  yield takeLatest(GET_ALL_ORGANISATION_REQUEST, getAllOrganisationSaga);
  yield takeLatest(CREATE_ORGANISATION_REQUEST, createOrganisationSaga);
  yield takeLatest(POST_STATUS_UPDATE_REQUEST, postStatusUpdate);
  yield takeLatest(POST_WEBSITE_UPDATE_REQUEST, postWebsiteSaga);
}
