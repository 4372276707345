export const GET_ALL_ORGANISATION_REQUEST = 'GET_ALL_ORGANISATION_REQUEST';
export const GET_ALL_ORGANISATION_SUCCESS = 'GET_ALL_ORGANISATION_SUCCESS';
export const GET_ALL_ORGANISATION_FAILURE = 'GET_ALL_ORGANISATION_FAILURE';

export const CREATE_ORGANISATION_REQUEST = 'CREATE_ORGANISATION_REQUEST';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAILURE = 'CREATE_ORGANISATION_FAILURE';

export const POST_STATUS_UPDATE_REQUEST = 'POST_STATUS_UPDATE_REQUEST';
export const POST_STATUS_UPDATE_SUCCESS = 'POST_STATUS_UPDATE_SUCCESS';
export const POST_STATUS_UPDATE_FAILURE = 'POST_STATUS_UPDATE_FAILURE';

export const POST_WEBSITE_UPDATE_REQUEST = 'POST_WEBSITE_UPDATE_REQUEST';
export const POST_WEBSITE_UPDATE_SUCCESS = 'POST_WEBSITE_UPDATE_SUCCESS';
export const POST_WEBSITE_UPDATE_FAILURE = 'POST_WEBSITE_UPDATE_FAILURE';
