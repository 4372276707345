import * as types from './actionType';

export const updateEventReducer = (data) => ({
  type: types.UPDATE_EVENT_REDUCER,
  payload: data
});

export const getAllEventsRequest = (data) => ({
  type: types.GET_ALL_EVENT_REQUEST,
  payload: data
});

export const getAllEventsSuccess = (data) => ({
  type: types.GET_ALL_EVENT_SUCCESS,
  payload: data
});

export const getAllEventsFailure = (data) => ({
  type: types.GET_ALL_EVENT_FAILURE,
  payload: data
});

export const addEventRequest = (data) => ({
  type: types.ADD_EVENT_REQUEST,
  payload: data
});

export const addEventSuccess = (data) => ({
  type: types.ADD_EVENT_SUCCESS,
  payload: data
});

export const addEventFailure = (data) => ({
  type: types.ADD_EVENT_FAILURE,
  payload: data
});

export const getSingleEventsRequest = (data) => ({
  type: types.GET_SINGLE_EVENT_REQUEST,
  payload: data
});

export const getSingleEventsSuccess = (data) => ({
  type: types.GET_SINGLE_EVENT_SUCCESS,
  payload: data
});

export const getSingleEventsFailure = (data) => ({
  type: types.GET_SINGLE_EVENT_FAILURE,
  payload: data
});

export const editEventRequest = (data) => ({
  type: types.EDIT_EVENT_REQUEST,
  payload: data
});

export const editEventSuccess = (data) => ({
  type: types.EDIT_EVENT_SUCCESS,
  payload: data
});

export const editEventFailure = (data) => ({
  type: types.EDIT_EVENT_FAILURE,
  payload: data
});

export const deleteEventRequest = (data) => ({
  type: types.DELETE_EVENT_REQUEST,
  payload: data
});

export const deleteEventSuccess = (data) => ({
  type: types.DELETE_EVENT_SUCCESS,
  payload: data
});

export const deleteEventFailure = (data) => ({
  type: types.DELETE_EVENT_FAILURE,
  payload: data
});

export const getAttendeeRequest = (data) => ({
  type: types.GET_ATTENDEE_REQUEST,
  payload: data
});

export const getAttendeeSuccess = (data) => ({
  type: types.GET_ATTENDEE_SUCCESS,
  payload: data
});

export const getAttendeeFailure = (data) => ({
  type: types.GET_ATTENDEE_FAILURE,
  payload: data
});

export const postAttendeeRequest = (data) => ({
  type: types.POST_ATTENDEE_REQUEST,
  payload: data
});

export const postAttendeeSuccess = (data) => ({
  type: types.POST_ATTENDEE_SUCCESS,
  payload: data
});

export const postAttendeeFailure = (data) => ({
  type: types.POST_ATTENDEE_FAILURE,
  payload: data
});

export const deleteAttendeeRequest = (data) => {
  return {
    type: types.DELETE_ATTENDEE_REQUEST,
    payload: data
  };
};

export const deleteAttendeeSuccess = (data) => ({
  type: types.DELETE_ATTENDEE_SUCCESS,
  payload: data
});

export const deleteAttendeeFailure = (data) => ({
  type: types.DELETE_ATTENDEE_FAILURE,
  payload: data
});

export const getMemberListRequest = (data) => {
  return {
    type: types.GET_MEMBERS_LIST_REQUEST,
    payload: data
  };
};

export const getMemberListSuccess = (data) => ({
  type: types.GET_MEMBERS_LIST_SUCCESS,
  payload: data
});

export const getMemberListFailure = (data) => ({
  type: types.GET_MEMBERS_LIST_FAILURE,
  payload: data
});
export const registerUpdatedEvent = (data) => ({
  type: types.REGISTER_UPDATED_EVENT_REQUEST,
  payload: data
});

export const registerUpdatedEventSuccess = (data) => ({
  type: types.REGISTER_UPDATED_EVENT_SUCCESS,
  payload: data
});

export const registerUpdatedEventFailure = (data) => ({
  type: types.REGISTER_UPDATED_EVENT_FAILURE,
  payload: data
});
