import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Popover, Form, Modal as AntdModal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import { Spin } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import CustomTable from '../../Components/Table';
import CustomIcon from '../../Constants/Icons';
import { DynamicMonthChanger } from './DynmaicMonthChanger';
import { SelectField } from '../../Components/FormFields/SelectField';
import { showModal } from '../../services/modal/actions';
import {
  LEADERSHIP_LEADER_BOARD,
  LEADERSHIP_PAIRING_SETTINGS
} from '../../services/modal/actionType';
import {
  getLeaderShipRatingRequest,
  getAvailableLeadersRequest
} from '../../services/leadership/action';
import QuickSearch from '../../Components/FormFields/QuickSearch';
import { ThemeButton } from '../../Components/FormFields/ThemeButton';
import _isEmpty from 'lodash/isEmpty';
export const LeaderShipContainer = ({
  showModal,
  getLeaderShipRatingRequest,
  user_id,
  leaderLoading,
  leaderShipTableData,
  getAvailableLeadersRequest
}) => {
  const [selectedMonth, setSelectedMonth] = useState({});
  const [editEnable, setEditEnable] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalAvailableLeaders, setTotalAvailableLeaders] = useState([]);
  const [tableLeaderRatingsData, setTableLeaderRatingsData] = useState([]);
  const [, setQuickSearchValue] = useState('');
  const { confirm } = AntdModal;

  useEffect(() => {
    if (leaderShipTableData?.data?.length && !editEnable) {
      const orderedData = OrderbyPartner(leaderShipTableData?.data);
      setTableLeaderRatingsData(orderedData);
    }
  }, [leaderShipTableData?.data, editEnable]);
  useEffect(() => {
    let start = moment(selectedMonth?.momentMonth).startOf('month').format('YYYY-MM-DD');
    let end = moment(selectedMonth?.momentMonth).endOf('month').format('YYYY-MM-DD');
    setStartDate(start);
    setEndDate(end);
    if (!_isEmpty(selectedMonth)) {
      getLeaderShipRating({
        rating_period: start
        // to: end
      });
    }
  }, [selectedMonth]);
  const getLeaderShipRating = (values = {}) => {
    let obj = {
      ...values,
      user_id,
      page: 1,
      limit: 999
    };
    let availableRequest = {
      ...values,
      user_id
    };
    getLeaderShipRatingRequest(obj);
    getAvailableLeadersRequest(availableRequest);
  };
  const openLeaderBoard = () => {
    showModal({
      modalType: LEADERSHIP_LEADER_BOARD,
      modalProps: {}
    });
  };

  const OrderbyPartner = (dataList) => {
    const data = Object.values(dataList);
    const ordered = data.sort(OrderbyNull());
    return ordered;
  };

  const OrderbyNull = () => {
    return function (a, b) {
      if (a.leader_2_id === b.leader_2_id) {
        return 0;
      }
      if (a.leader_2_id === null) {
        return 1;
      }
      if (b.leader_2_id === null) {
        return -1;
      }
      return a < b ? -1 : 1;
    };
  };

  const openLeaderShipSettings = () => {
    showModal({
      modalType: LEADERSHIP_PAIRING_SETTINGS,
      modalProps: {}
    });
  };
  const ratingPopoverContent = (rating, type) => {
    let selectedRating = {};
    if (type === 'rating1') {
      selectedRating = rating?.leader_1_points_breakdown;
    } else {
      selectedRating = rating?.leader_2_points_breakdown;
    }
    const { care, discipline, leadership, problem_solving, system_knowledge } = selectedRating;
    return (
      <div>
        <div>
          Leadership / <span className="font-weight-bold">{leadership}</span>
        </div>
        <div>
          System Knowledge /<span className="font-weight-bold"> {system_knowledge}</span>
        </div>
        <div>
          Discipline / <span className="font-weight-bold">{discipline}</span>
        </div>
        <div>
          Problem Solving / <span className="font-weight-bold">{problem_solving}</span>
        </div>
        <div>
          Care / <span className="font-weight-bold"> {care}</span>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (leaderShipTableData?.data?.length) {
      let constructedData = leaderShipTableData?.data?.filter((val) => {
        return val?.leader_2_id === null;
      });
      const dataFinal = constructedData.map((val) => {
        return {
          label: `${val.leader_1}`,
          value: val.leader_1_id,
          id: val.leader_1_id
        };
      });
      setTotalAvailableLeaders(dataFinal);
    }
  }, [leaderShipTableData?.data]);

  const columns = useMemo(
    () => [
      {
        title: 'Partner 1',
        dataIndex: 'leader_1 ',
        key: 'leader_1',
        render: (title, data) => {
          return (
            <>
              {editEnable ? (
                <div>{<SelectField options={totalAvailableLeaders} />}</div>
              ) : (
                `${data?.leader_1}`
              )}
            </>
          );
        }
      },
      {
        title: 'Rating',
        dataIndex: 'leader_1_points',
        key: 'leader_1_points',
        render: (title, data) => {
          return (
            <div className="cursor-pointer">
              <Popover
                placement="right"
                content={ratingPopoverContent(data, 'rating1')}
                trigger="hover">
                {data?.leader_1_points}
              </Popover>
            </div>
          );
        }
      },
      {
        title: 'Partner 2',
        dataIndex: 'leader_2 ',
        key: 'leader_2',
        render: (title, data) => {
          return (
            <>
              {editEnable ? (
                <div>{<SelectField options={totalAvailableLeaders} />}</div>
              ) : data?.leader_2_id !== null ? (
                `${data?.leader_2}`
              ) : (
                <div className="ant-table-cell-partner-empty">{data?.leader_2}</div>
              )}
            </>
          );
        }
      },
      {
        title: 'Rating',
        dataIndex: 'leader_2_points',
        key: 'leader_2_points',
        render: (title, data) => {
          return (
            <div className="cursor-pointer">
              <Popover
                placement="right"
                content={() => ratingPopoverContent(data, 'rating2')}
                trigger="hover">
                {data?.leader_2_points}
              </Popover>
            </div>
          );
        }
      },
      {
        title: () => (
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <div> </div>
              {editEnable ? (
                <div>
                  {' '}
                  <PlusCircleOutlined
                    onClick={() => addNewLeaderRatings()}
                    style={{ borderRadius: '50%', fontSize: '20px' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ),
        width: 50
      }
    ],
    [editEnable, tableLeaderRatingsData]
  );

  let checkMonth = false;
  if (
    moment().endOf('month').format('MMMM') === selectedMonth.monthDetail &&
    moment().endOf('year').format('YYYY') === selectedMonth.year
  ) {
    checkMonth = true;
  }
  const handleGlobalSearch = (e) => {
    setQuickSearchValue(e.target.value);
    let obj = {
      rating_period: startDate,
      // to: endDate,
      search_kw: e.target.value
    };
    getLeaderShipRating(obj);
  };
  const onSearch = _debounce(handleGlobalSearch, 500);
  // console.log('startDatestartDate', startDate);
  const fetchMoreTable = useCallback(() => {
    if (leaderShipTableData?.next_page_url) {
      let obj = {
        rating_period: startDate,
        // to: endDate,
        page: 2
      };
      getLeaderShipRating(obj);
    }
  }, [startDate, endDate, leaderShipTableData]);
  const addNewLeaderRatings = () => {
    let obj = {
      leader_1: '',
      leader_1_points: 0,
      leader_1_points_breakdown: {
        leadership: 0,
        system_knowledge: 0,
        discipline: 0,
        problem_solving: 0,
        care: 0
      },
      leader_2: '',
      leader_2_points: 0,
      leader_2_points_breakdown: {
        leadership: 0,
        system_knowledge: 0,
        discipline: 0,
        problem_solving: 0,
        care: 0
      }
    };
    let temp = [...tableLeaderRatingsData, obj];
    setTableLeaderRatingsData(temp);
  };
  return (
    <div>
      <Spin spinning={leaderLoading}>
        <Form>
          <div className="d-flex justify-content-between">
            <div>
              <DynamicMonthChanger
                editIconEnable={setEditEnable}
                diableButtons={checkMonth}
                month={true}
                monthChanged={setSelectedMonth}
              />
            </div>
            <div className="d-flex">
              <div>
                <CustomIcon
                  onClick={() => openLeaderShipSettings()}
                  icon="settings"
                  className="me-2 ms-2"
                />
              </div>
              <div>
                <CustomIcon onClick={() => openLeaderBoard()} icon="result" className="me-2 ms-2" />
              </div>
              <div>
                <CustomIcon
                  icon="edit"
                  onClick={() => setEditEnable(!editEnable)}
                  className={`${!checkMonth ? 'disableButton' : ''} me-3 ms-2`}
                />
              </div>
              <div>
                <QuickSearch
                  onChange={(e) => {
                    onSearch(e);
                  }}
                />
              </div>
              <div>
                <ThemeButton type="submit" className="green-button ms-2">
                  Save
                </ThemeButton>
              </div>
              <div>
                <ThemeButton
                  disabled={!editEnable}
                  onClick={() =>
                    confirm({
                      title: 'Warning',
                      content: 'Are you sure you want to cancel ?',
                      okText: 'Yes',
                      cancelText: 'Cancel',
                      onOk: () => setEditEnable(false)
                    })
                  }
                  className="lightGrey-button ms-2">
                  cancel
                </ThemeButton>
              </div>
            </div>
          </div>
          <CustomTable
            columns={columns}
            dataSource={tableLeaderRatingsData ?? []}
            scroll={{ y: 300 }}
            fetchMore={fetchMoreTable}
            // ref={scrollRef}
          />
        </Form>
      </Spin>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user_id: state?.auth?.authData.id,
    leaderLoading: state?.leaderShip?.leaderRatingLoading,
    leaderShipTableData: state?.leaderShip?.leaderShipRatingData,
    availableLeaders: state?.leaderShip?.avaialbleLeaders
  };
};
export default connect(mapStateToProps, {
  showModal,
  getLeaderShipRatingRequest,
  getAvailableLeadersRequest
})(LeaderShipContainer);
