export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// export const LOGIN_VERIFICATION_REQUEST = 'LOGIN_VERIFICATION_REQUEST';
// export const LOGIN_VERIFICATION_SUCCESS = 'LOGIN_VERIFICATION_SUCCESS';
// export const LOGIN_VERIFICATION_FAILURE = 'LOGIN_VERIFICATION_FAILURE';

// export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
// export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
// export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
