import * as types from './actionType';

function resourceReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        getAllCategoryLoading: true
      };
    case types.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        getAllCategoryLoading: false,
        categories: payload
      };
    case types.GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        getAllCategoryLoading: false
      };

    case types.ADD_CATEGORY_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.GET_SINGLE_CATEGORY_REQUEST:
      return {
        ...state,
        getCategoryLoading: true
      };
    case types.GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategoryLoading: false,
        category: payload
      };
    case types.GET_SINGLE_CATEGORY_FAILURE:
      return {
        ...state,
        getCategoryLoading: false
      };

    case types.EDIT_CATEGORY_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.EDIT_CATEGORY_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        deleteCategoryLoading: true
      };
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategoryLoading: false
      };
    case types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        deleteCategoryLoading: false
      };

    case types.GET_ALL_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        getAllSubCategoryLoading: true,
        subCategories: []
      };
    case types.GET_ALL_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        getAllSubCategoryLoading: false,
        subCategories: payload
      };
    case types.GET_ALL_SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        getAllSubCategoryLoading: false,
        subCategories: []
      };

    case types.GET_ALL_ITEMS_REQUEST:
      return {
        ...state,
        getAllItemLoading: true
      };
    case types.GET_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        getAllItemLoading: false,
        items: payload
      };
    case types.GET_ALL_ITEMS_FAILURE:
      return {
        ...state,
        getAllItemLoading: false
      };

    case types.UPDATE_REOURCE_REDUCER:
      return {
        ...state,
        ...payload
      };

    // SUB-CATEGORIES START
    case types.ADD_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.ADD_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.ADD_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.GET_SINGLE_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        getCategoryLoading: true
      };
    case types.GET_SINGLE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategoryLoading: false,
        subCategory: payload
      };
    case types.GET_SINGLE_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        getCategoryLoading: false
      };

    case types.EDIT_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.EDIT_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.EDIT_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.DELETE_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        deleteCategoryLoading: true
      };
    case types.DELETE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategoryLoading: false
      };
    case types.DELETE_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        deleteCategoryLoading: false
      };

    // SUB-CATEGORIES END

    // SUB-CATEGORIES-ITEMS START
    case types.ADD_ITEM_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.ADD_ITEM_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.GET_SINGLE_ITEM_REQUEST:
      return {
        ...state,
        getCategoryLoading: true
      };
    case types.GET_SINGLE_ITEM_SUCCESS:
      return {
        ...state,
        getCategoryLoading: false,
        subItem: payload
      };
    case types.GET_SINGLE_ITEM_FAILURE:
      return {
        ...state,
        getCategoryLoading: false
      };

    case types.EDIT_ITEM_REQUEST:
      return {
        ...state,
        addCategoryLoading: true
      };
    case types.EDIT_ITEM_SUCCESS:
      return {
        ...state,
        addCategoryLoading: false
      };
    case types.EDIT_ITEM_FAILURE:
      return {
        ...state,
        addCategoryLoading: false
      };

    case types.DELETE_ITEM_REQUEST:
      return {
        ...state,
        deleteCategoryLoading: true
      };
    case types.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        deleteCategoryLoading: false
      };
    case types.DELETE_ITEM_FAILURE:
      return {
        ...state,
        deleteCategoryLoading: false
      };

    // SUB-CATEGORIES-ITEMS END

    default:
      return state;
  }
}

export default resourceReducer;
