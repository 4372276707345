export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';

export const UPDATE_EVENT_REDUCER = 'UPDATE_EVENT_REDUCER';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const GET_SINGLE_EVENT_REQUEST = 'GET_SINGLE_EVENT_REQUEST';
export const GET_SINGLE_EVENT_SUCCESS = 'GET_SINGLE_EVENT_SUCCESS';
export const GET_SINGLE_EVENT_FAILURE = 'GET_SINGLE_EVENT_FAILURE';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const GET_ALL_EVENT_REQUEST = 'GET_ALL_EVENT_REQUEST';
export const GET_ALL_EVENT_SUCCESS = 'GET_ALL_EVENT_SUCCESS';
export const GET_ALL_EVENT_FAILURE = 'GET_ALL_EVENT_FAILURE';

export const GET_ATTENDEE_REQUEST = 'GET_ATTENDEE_REQUEST';
export const GET_ATTENDEE_SUCCESS = 'GET_ATTENDEE_SUCCESS';
export const GET_ATTENDEE_FAILURE = 'GET_ATTENDEE_FAILURE';

export const POST_ATTENDEE_REQUEST = 'POST_ATTENDEE_REQUEST';
export const POST_ATTENDEE_SUCCESS = 'POST_ATTENDEE_SUCCESS';
export const POST_ATTENDEE_FAILURE = 'POST_ATTENDEE_FAILURE';

export const DELETE_ATTENDEE_REQUEST = 'DELETE_ATTENDEE_REQUEST';
export const DELETE_ATTENDEE_SUCCESS = 'DELETE_ATTENDEE_SUCCESS';
export const DELETE_ATTENDEE_FAILURE = 'DELETE_ATTENDEE_FAILURE';

export const GET_MEMBERS_LIST_REQUEST = 'GET_MEMBERS_LIST_REQUEST';
export const GET_MEMBERS_LIST_SUCCESS = 'GET_MEMBERS_LIST_SUCCESS';
export const GET_MEMBERS_LIST_FAILURE = 'GET_MEMBERS_LIST_FAILURE';

export const REGISTER_UPDATED_EVENT_REQUEST = 'REGISTER_UPDATED_EVENT_REQUEST';
export const REGISTER_UPDATED_EVENT_SUCCESS = 'REGISTER_UPDATED_EVENT_SUCCESS';
export const REGISTER_UPDATED_EVENT_FAILURE = 'REGISTER_UPDATED_EVENT_FAILURE';
