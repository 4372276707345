import React from 'react';
import { notification } from 'antd';

const openNotification = (notificationType, message, placement = 'topRight', duration = 3) => {
  notification[notificationType]({
    key: message,
    message: <div style={{ zIndex: 2001 }} dangerouslySetInnerHTML={{ __html: message }} />,
    duration,
    placement
  });
};

export default openNotification;
