import * as types from './actionType';

function authReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLogging: true };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLogging: false,
        authData: payload
      };
    case types.LOGIN_FAILURE:
      return { ...state, isLogging: false, verificationData: null };

    // case types.LOGIN_VERIFICATION_REQUEST:
    //   return { ...state, isLogging: true };
    // case types.LOGIN_VERIFICATION_SUCCESS:
    //   return {
    //     ...state,
    //     isLogging: false,
    //     verificationData: null,
    //     authData: payload
    //   };
    // case types.LOGIN_VERIFICATION_FAILURE:
    //   return { ...state, isLogging: false, };

    // case types.RESEND_OTP_REQUEST:
    //   return { ...state, sendingOtp: true };
    // case types.RESEND_OTP_SUCCESS:
    //   return {
    //     ...state,
    //     sendingOtp: false,
    //   };
    // case types.RESEND_OTP_FAILURE:
    //   return { ...state, sendingOtp: false, };

    case types.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true };
    case types.LOGOUT_SUCCESS:
      return { ...state, isLoggingOut: false, authData: null };
    case types.LOGOUT_FAILURE:
      return { ...state, isLoggingOut: false };

    default:
      return state;
  }
}

export default authReducer;
