import { call, put, takeLatest } from 'redux-saga/effects';

import openNotification from '../../Components/Notification';
// import { VERFICATION_PATH } from "../../Constants/routes";
import { clearAll, setStorage } from '../../store/localStorage';
import {
  loginFailure,
  loginSuccess,
  /* loginVerificationFailure, loginVerificationSuccess, */ logOutFailure,
  logOutSuccess /*  resendOtpFailure, resendOtpSuccess */
} from './actions';
import {
  LOGIN_REQUEST,
  /* LOGIN_VERIFICATION_REQUEST, RESEND_OTP_REQUEST, */ LOGOUT_REQUEST
} from './actionType';
import { login /* loginVerification, resendOtp */ } from './services';

function* logInSaga(action) {
  const { ...rest } = action.payload;
  try {
    const { response } = yield call(login, rest);
    if (response?.status) {
      yield put(loginSuccess({ id: response.data?.id }));
      yield call(setStorage, 'auth', response.data);
      yield call(setStorage, 'apiToken', { token: response?.apitoken });
      // history(VERFICATION_PATH);
    } else {
      openNotification('error', response.message);
      yield put(loginFailure());
    }
  } catch (err) {
    yield put(loginFailure());
  }
}

// function* logInVerificationSaga(action) {
//   try {
//     const { response } = yield call(loginVerification, action.payload)
//     if (response?.status && response?.data?.code_verified) {
//       yield put(loginVerificationSuccess(response.data))
//       yield call(setStorage, 'auth', response.data);
//     } else {
//       yield put(loginVerificationFailure())
//       openNotification('error', response.message)
//     }
//   } catch (error) {
//     yield put(loginVerificationFailure())
//   }
// }

// function* resendOtpSaga(action) {
//   try {
//     const { response } = yield call(resendOtp, action.payload)
//     if (response.status) {
//       yield put(resendOtpSuccess())
//       openNotification('success', response.message)
//     } else {
//       yield put(resendOtpFailure())
//       openNotification('error', response.message)
//     }
//   } catch (error) {
//     yield put(resendOtpFailure())
//   }
// }

function* logOutSaga() {
  try {
    yield call(clearAll);
    yield put(logOutSuccess());
  } catch (error) {
    yield put(logOutFailure());
  }
}

export default function* watchAuth() {
  yield takeLatest(LOGIN_REQUEST, logInSaga);
  // yield takeLatest(LOGIN_VERIFICATION_REQUEST, logInVerificationSaga);
  // yield takeLatest(RESEND_OTP_REQUEST, resendOtpSaga);
  yield takeLatest(LOGOUT_REQUEST, logOutSaga);
}
