import React from 'react';
// import { Input } from 'antd';

import { BACKGROUND_IMAGES } from '../../Constants';

// const { Search } = Input;

const SearchContainer = ({ title }) => {
  // const handleSearch = (e) => {
  //     console.log('handleSearch---------------', e);
  // };

  return (
    <div
      className="search-container-wrapper"
      style={{ backgroundImage: `url(${BACKGROUND_IMAGES.bg})` }}>
      <div className="title">{title}</div>
      {/* <Search size="large" onSearch={handleSearch} className='search-field' /> */}
    </div>
  );
};

export default SearchContainer;
