import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BASE_PATH_PREFIX } from '../Constants/routes';
const Login = lazy(() => import('../Containers/Login'));

const PublicRoute = ({ isLogged, ...rest }) => (
  <Routes>
    <Route index element={isLogged ? <Navigate to={BASE_PATH_PREFIX} /> : <Login {...rest} />} />
  </Routes>
);

const mapStateToProps = ({ auth }) => {
  return {
    isLogged: !!auth.authData,
    verificationData: auth.verificationData
  };
};

export default connect(mapStateToProps)(PublicRoute);
