const store = window.localStorage;

const KEYS_TO_KEEP = ['wiseberry.trustedDevice.key', 'wiseberry.trustedDevice.created'];

export const getStorage = (key) => JSON.parse(store.getItem(key));

export const setStorage = (key, value) => store.setItem(key, JSON.stringify(value));

export const removeStorage = (key) => store.removeItem(key);

export const clearAll = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!KEYS_TO_KEEP.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

export const hasStorage = (key) => {
  const item = getStorage(key);
  return !!item;
};
