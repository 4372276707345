import * as types from './actionType';

export const updateResourceReducer = (data) => ({
  type: types.UPDATE_REOURCE_REDUCER,
  payload: data
});

// Categories

export const getAllCategoriesRequest = (data) => ({
  type: types.GET_ALL_CATEGORIES_REQUEST,
  payload: data
});
export const getAllCategoriesSuccess = (data) => ({
  type: types.GET_ALL_CATEGORIES_SUCCESS,
  payload: data
});
export const getAllCategoriesFailure = (data) => ({
  type: types.GET_ALL_CATEGORIES_FAILURE,
  payload: data
});

export const addCategoryRequest = (data) => ({
  type: types.ADD_CATEGORY_REQUEST,
  payload: data
});
export const addCategorySuccess = (data) => ({
  type: types.ADD_CATEGORY_SUCCESS,
  payload: data
});
export const addCategoryFailure = (data) => ({
  type: types.ADD_CATEGORY_FAILURE,
  payload: data
});

export const getSingleCategoryRequest = (data) => ({
  type: types.GET_SINGLE_CATEGORY_REQUEST,
  payload: data
});
export const getSingleCategorySuccess = (data) => ({
  type: types.GET_SINGLE_CATEGORY_SUCCESS,
  payload: data
});
export const getSingleCategoryFailure = (data) => ({
  type: types.GET_SINGLE_CATEGORY_FAILURE,
  payload: data
});

export const editCategoryRequest = (data) => ({
  type: types.EDIT_CATEGORY_REQUEST,
  payload: data
});
export const editCategorySuccess = (data) => ({
  type: types.EDIT_CATEGORY_SUCCESS,
  payload: data
});
export const editCategoryFailure = (data) => ({
  type: types.EDIT_CATEGORY_FAILURE,
  payload: data
});

export const deleteCategoryRequest = (data) => ({
  type: types.DELETE_CATEGORY_REQUEST,
  payload: data
});
export const deleteCategorySuccess = (data) => ({
  type: types.DELETE_CATEGORY_SUCCESS,
  payload: data
});
export const deleteCategoryFailure = (data) => ({
  type: types.DELETE_CATEGORY_FAILURE,
  payload: data
});

// Sub-Categtories

export const getAllSubCategoriesRequest = (data) => ({
  type: types.GET_ALL_SUB_CATEGORIES_REQUEST,
  payload: data
});
export const getAllSubCategoriesSuccess = (data) => ({
  type: types.GET_ALL_SUB_CATEGORIES_SUCCESS,
  payload: data
});
export const getAllSubCategoriesFailure = (data) => ({
  type: types.GET_ALL_SUB_CATEGORIES_FAILURE,
  payload: data
});

export const addSubCategoryRequest = (data) => ({
  type: types.ADD_SUB_CATEGORY_REQUEST,
  payload: data
});
export const addSubCategorySuccess = (data) => ({
  type: types.ADD_SUB_CATEGORY_SUCCESS,
  payload: data
});
export const addSubCategoryFailure = (data) => ({
  type: types.ADD_SUB_CATEGORY_FAILURE,
  payload: data
});

export const getSingleSubCategoryRequest = (data) => ({
  type: types.GET_SINGLE_SUB_CATEGORY_REQUEST,
  payload: data
});
export const getSingleSubCategorySuccess = (data) => ({
  type: types.GET_SINGLE_SUB_CATEGORY_SUCCESS,
  payload: data
});
export const getSingleSubCategoryFailure = (data) => ({
  type: types.GET_SINGLE_SUB_CATEGORY_FAILURE,
  payload: data
});

export const editSubCategoryRequest = (data) => ({
  type: types.EDIT_SUB_CATEGORY_REQUEST,
  payload: data
});
export const editSubCategorySuccess = (data) => ({
  type: types.EDIT_SUB_CATEGORY_SUCCESS,
  payload: data
});
export const editSubCategoryFailure = (data) => ({
  type: types.EDIT_SUB_CATEGORY_FAILURE,
  payload: data
});

export const deleteSubCategoryRequest = (data) => ({
  type: types.DELETE_SUB_CATEGORY_REQUEST,
  payload: data
});
export const deleteSubCategorySuccess = (data) => ({
  type: types.DELETE_SUB_CATEGORY_SUCCESS,
  payload: data
});
export const deleteSubCategoryFailure = (data) => ({
  type: types.DELETE_SUB_CATEGORY_FAILURE,
  payload: data
});

// Items

export const getAllItemsRequest = (data) => ({
  type: types.GET_ALL_ITEMS_REQUEST,
  payload: data
});
export const getAllItemsSuccess = (data) => ({
  type: types.GET_ALL_ITEMS_SUCCESS,
  payload: data
});
export const getAllItemsFailure = (data) => ({
  type: types.GET_ALL_ITEMS_FAILURE,
  payload: data
});

export const addItemRequest = (data) => ({
  type: types.ADD_ITEM_REQUEST,
  payload: data
});
export const addItemSuccess = (data) => ({
  type: types.ADD_ITEM_SUCCESS,
  payload: data
});
export const addItemFailure = (data) => ({
  type: types.ADD_ITEM_FAILURE,
  payload: data
});

export const getSingleItemRequest = (data) => ({
  type: types.GET_SINGLE_ITEM_REQUEST,
  payload: data
});
export const getSingleItemSuccess = (data) => ({
  type: types.GET_SINGLE_ITEM_SUCCESS,
  payload: data
});
export const getSingleItemFailure = (data) => ({
  type: types.GET_SINGLE_ITEM_FAILURE,
  payload: data
});

export const editItemRequest = (data) => ({
  type: types.EDIT_ITEM_REQUEST,
  payload: data
});
export const editItemSuccess = (data) => ({
  type: types.EDIT_ITEM_SUCCESS,
  payload: data
});
export const editItemFailure = (data) => ({
  type: types.EDIT_ITEM_FAILURE,
  payload: data
});

export const deleteItemRequest = (data) => ({
  type: types.DELETE_ITEM_REQUEST,
  payload: data
});
export const deleteItemSuccess = (data) => ({
  type: types.DELETE_ITEM_SUCCESS,
  payload: data
});
export const deleteItemFailure = (data) => ({
  type: types.DELETE_ITEM_FAILURE,
  payload: data
});
