import React, { lazy } from 'react';
import { Link, useParams } from 'react-router-dom';
import SearchContainer from '../../Components/SearchContainer';
import CustomIcon from '../../Constants/Icons';

const MENU = [
  { title: 'Tutorials', icon: 'book', path: 'tutorials' },
  { title: 'FAQs', icon: 'faqs', path: 'FAQs' },
  { title: 'Quick Info', icon: 'support', path: 'quickInfo' }
];

const Tutorials = lazy(() => import('./Tutorials'));
const FAQs = lazy(() => import('./FAQs'));
const QuickInfo = lazy(() => import('./QuickInfo'));

const ParamsMap = {
  tutorials: { title: 'Tutorials', RenderComponent: Tutorials },
  FAQs: { title: 'FAQs', RenderComponent: FAQs },
  quickInfo: { title: 'Quick Info', RenderComponent: QuickInfo }
};
function SuppportMaterial() {
  let urlParams = useParams();
  if (urlParams?.sub_page) {
    const { title, RenderComponent } = ParamsMap[urlParams.sub_page];
    return (
      <div>
        <SearchContainer title={title} />
        <RenderComponent />
      </div>
    );
  } else {
    return (
      <div className="result-page-wrapper">
        <SearchContainer title="SuppportMaterial" />
        <div className="d-flex justify-content-center align-items-center">
          <div className="home-menu-container mt-3">
            {MENU.map((item, key) => (
              <Link to={item.path} className="nav-link" key={key}>
                <div className="menu-items">
                  <div>
                    <CustomIcon icon={item.icon} className="menu-icon" color="#555555" />
                  </div>
                  <div className="menu-title">{item.title}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SuppportMaterial;
