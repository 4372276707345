import React from 'react';
import { connect } from 'react-redux';
import SearchContainer from '../../Components/SearchContainer';
import { FranchiseReportContainer } from './FranchiseReportContainer';

export const FranchiseReports = () => {
  return (
    <div id="franchiseReports">
      <div>
        <SearchContainer title="Franchise Reports" />
      </div>
      <div className="d-flex flex-column p-3 container leadership-wrapper" id="franchiseReports">
        <FranchiseReportContainer />
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseReports);
