import * as types from './actionType';

export const loginRequest = (data) => ({
  type: types.LOGIN_REQUEST,
  payload: data
});
export const loginSuccess = (data) => ({
  type: types.LOGIN_SUCCESS,
  payload: data
});
export const loginFailure = (data) => ({
  type: types.LOGIN_FAILURE,
  payload: data
});

// export const loginVerificationRequest = data => ({
//   type: types.LOGIN_VERIFICATION_REQUEST,
//   payload: data
// });
// export const loginVerificationSuccess = data => ({
//   type: types.LOGIN_VERIFICATION_SUCCESS,
//   payload: data
// });
// export const loginVerificationFailure = data => ({
//   type: types.LOGIN_VERIFICATION_FAILURE,
//   payload: data
// });

// export const resendOtpRequest = data => ({
//   type: types.RESEND_OTP_REQUEST,
//   payload: data
// });
// export const resendOtpSuccess = data => ({
//   type: types.RESEND_OTP_SUCCESS,
//   payload: data
// });
// export const resendOtpFailure = data => ({
//   type: types.RESEND_OTP_FAILURE,
//   payload: data
// });

export const logOutRequest = (data) => ({
  type: types.LOGOUT_REQUEST,
  payload: data
});
export const logOutSuccess = (data) => ({
  type: types.LOGOUT_SUCCESS,
  payload: data
});
export const logOutFailure = (data) => ({
  type: types.LOGOUT_FAILURE,
  payload: data
});
