import * as types from './actionType';

const modalReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        modalType: [...state.modalType, payload.modalType],
        modalProps: [...state.modalProps, payload.modalProps || {}],
        modalVariant: payload.modalVariant
      };
    case types.HIDE_MODAL:
      // eslint-disable-next-line no-case-declarations
      let index;
      if (payload) {
        index = state.modalType.findIndex((item) => item === payload.modalType);
      }
      return {
        ...state,
        modalType: !payload ? [] : state.modalType.filter((_, _index) => _index !== index),
        modalProps: !payload ? [] : state.modalProps.filter((_, _index) => _index !== index),
        modalVariant: ''
      };
    default:
      return state;
  }
};

export default modalReducer;
