import axios from 'axios';

import store from '../store';
import { logOutRequest } from './auth/actions';
import { getStorage, hasStorage } from '../store/localStorage';
import openNotification from '../Components/Notification';

const grant_type = 'password';
const client_id = 1;
const client_secret = 'vrUohoUGuqykR1bV3TLh77mdLSA9QL2jDzaivwrX';

const WiseBerryAPIEnv = process.env.REACT_APP_WISEBERRY_API;
const WolAPI = process.env.REACT_APP_WISEBERRY_WOL_API;

const client = axios.create({
  baseURL: WiseBerryAPIEnv
});

export const wolLogin = (
  username,
  password,
  root = null,
  trustedDevice = null,
  mfa_code = null,
  trust_this_device = null,
  oauthsootoken = null
) => {
  return axios.post(`${WolAPI}login`, {
    username,
    password,
    root,
    client_id,
    grant_type,
    client_secret,
    trustedDevice,
    mfa_code,
    trust_this_device,
    oauthsootoken
  });
};

const request = ({ isFileUpload = false, ...options }) => {
  let isWolApi = false;
  if (options.method === 'post') {
    let formData = new FormData();
    if (isFileUpload) {
      for (let key in options.data) {
        isWolApi = key === 'wolEndpoint' ? true : isWolApi;
        formData.append(key, options.data[key]);
      }
    }
    options.data = isFileUpload ? formData : options.data;
  }

  if (hasStorage('apiToken')) {
    const { token } = getStorage('apiToken');
    client.defaults.headers.common['x-api-key'] = token;
  } else {
    delete client.defaults.headers.common['x-api-key'];
  }

  if (options.data.wolEndpoint || isWolApi) {
    options.baseURL = WolAPI;
  }

  return client(options)
    .then((response) => {
      if (!response?.data?.status && response?.data?.message === 'Invalid User / Token') {
        openNotification('error', 'User session expired');
        store.dispatch(logOutRequest());
      }
      return { response: response.data };
    })
    .catch(() => {});
};

export const post = (url, data) =>
  request({
    url,
    method: 'post',
    data
  });

export const get = (url, data) =>
  request({
    url,
    method: 'get',
    data
  });

export const fileUpload = (url, data, method = 'post') => {
  return request({
    url,
    data,
    method,
    isFileUpload: true,
    headers: { 'content-type': 'multipart/form-data' }
  });
};
