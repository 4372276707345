import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import initialState from './initialState';
import rootSaga from '../services/saga';
import authReducer from '../services/auth/reducer';
import modalReducer from '../services/modal/reducer';
import eventReducer from '../services/events/reducer';
import organisationReducer from '../services/organisations/reducer';
import resourceReducer from '../services/resources/reducer';
import leadershipReducer from '../services/leadership/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  events: eventReducer,
  resources: resourceReducer,
  organisation: organisationReducer,
  leaderShip: leadershipReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
