import * as types from './actionType';

export const getLeaderShipRatingRequest = (data) => ({
  type: types.GET_LEADERSHIP_RATING_REQUEST,
  payload: data
});

export const getLeaderShipRatingSuccess = (data) => ({
  type: types.GET_LEADERSHIP_RATING_SUCCESS,
  payload: data
});

export const getLeaderShipRatingFailure = (data) => ({
  type: types.GET_LEADERSHIP_RATING_FAILURE,
  payload: data
});

export const getTopLeaderRequest = (data) => ({
  type: types.GET_TOP_LEADER_REQUEST,
  payload: data
});

export const getTopLeaderSuccess = (data) => ({
  type: types.GET_TOP_LEADER_SUCCESS,
  payload: data
});

export const getTopLeaderFailure = (data) => ({
  type: types.GET_TOP_LEADER_FAILURE,
  payload: data
});

export const getLeadershipSettingsOrganisationRequest = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST,
  payload: data
});

export const getLeadershipSettingsOrganisationSuccess = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_ORGANISATION_SUCCESS,
  payload: data
});

export const getLeadershipSettingsOrganisationFailure = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_ORGANISATION_FAILURE,
  payload: data
});

export const getLeadershipSettingsLeadersRequest = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST,
  payload: data
});

export const getLeadershipSettingsLeadersSuccess = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_LEADERS_SUCCESS,
  payload: data
});

export const getLeadershipSettingsLeadersFailure = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_LEADERS_FAILURE,
  payload: data
});

export const getLeadershipLeaderRatingsSettingsRequest = (data) => ({
  type: types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST,
  payload: data
});

export const getLeadershipLeaderRatingsSettingsSuccess = (data) => ({
  type: types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_SUCCESS,
  payload: data
});

export const getLeadershipLeaderRatingsSettingsFailure = (data) => ({
  type: types.GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_FAILURE,
  payload: data
});

export const getLeadershipSettingsRequest = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_REQUEST,
  payload: data
});

export const getLeadershipSettingsSuccess = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_SUCCESS,
  payload: data
});

export const getLeadershipSettingsFailure = (data) => ({
  type: types.GET_LEADERSHIP_SETTINGS_FAILURE,
  payload: data
});
export const getAvailableLeadersRequest = (data) => ({
  type: types.GET_AVAILABLE_LEADERSHIP_REQUEST,
  payload: data
});

export const getAvailableLeadersSuccess = (data) => ({
  type: types.GET_AVAILABLE_LEADERSHIP_SUCCESS,
  payload: data
});

export const getAvailableLeadersFailure = (data) => ({
  type: types.GET_AVAILABLE_LEADERSHIP_FAILURE,
  payload: data
});
