import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

import Row from './Row';
import Cell from './Cell';

const SortableBody = SortableContainer((props) => <tbody {...props} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const CustomTable = ({
  columns,
  data,
  selectAll,
  onSelectChange,
  rowSelection,
  dragEnabled,
  pagination,
  fetchMore,
  ...rest
}) => {
  const scrollRef = useRef(null);

  const [sortValues, setSortValues] = useState({});
  const [dataSource, setDataSource] = useState([]);

  const handleTableChange = (pagination, filters, sorter) => {
    setSortValues(sorter);
  };

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    onSelectChange(selectedRowKeys, selectedRows);
  };

  const values = {};
  if (selectAll) {
    values.rowSelection = {
      type: 'checkbox',
      onChange: handleSelectChange,
      ...rowSelection
    };
  }

  useEffect(() => {
    let element = scrollRef?.current?.getElementsByClassName('ant-table-body') ?? null;
    if (element?.length && fetchMore) {
      element = element[0];
      element.addEventListener(
        'scroll',
        () => {
          const perc = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
          if (perc >= 100) {
            fetchMore();
          }
        },
        true
      );
    }
    return () => {
      if (element && fetchMore) {
        element.removeEventListener('scroll', () => {});
      }
    };
  }, [fetchMore]);

  useEffect(() => {
    let dataSource = data.map((value, index) => {
      if (!value.key) {
        return {
          ...value,
          key: value.id || index
        };
      }
      return value;
    });
    setDataSource(dataSource);
  }, [data]);

  // let dataSource = useMemo(() => data.map((value, index) => {
  //     if (!value.key) {
  //         return {
  //             ...value,
  //             key: value.id || index
  //         }
  //     }
  //     return value;
  // }), [data]);

  const dataColumns = useMemo(() => {
    let _columns = columns.map((column) => {
      if (column.sorter) {
        return {
          ...column,
          sortOrder: sortValues?.column?.dataIndex === column.dataIndex && sortValues.order,
          showSorterTooltip: false
        };
      }
      return { ...column };
    });
    if (dragEnabled) {
      _columns = [
        ..._columns,
        {
          title: '',
          dataIndex: 'sort',
          width: 50,
          className: 'drag-visible',
          render: () => <DragHandle />
        }
      ];
    }
    return _columns;
  }, [columns, sortValues, dragEnabled]);

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.key === restProps['data-row-key']);
    // console.log('dataSource', dataSource, index, restProps);
    return <SortableItem index={index} {...restProps} />;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let sortedDS = [...dataSource];
      let draggedItem = sortedDS[oldIndex];
      sortedDS = sortedDS.filter((_, index) => index !== oldIndex);
      sortedDS.splice(newIndex, 0, draggedItem);
      setDataSource(sortedDS);
      // const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(
      //     el => !!el,
      // );
      // console.log('Sorted items: ', newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  return (
    <div className="table-wrapper">
      <Table
        ref={scrollRef}
        components={{
          body: dragEnabled
            ? {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            : {
                row: Row,
                cell: Cell
              }
        }}
        columns={dataColumns}
        dataSource={dataSource}
        onChange={handleTableChange}
        pagination={pagination}
        {...values}
        {...rest}
      />
    </div>
  );
};

CustomTable.defaultProps = {
  columns: [],
  data: [],
  selectAll: false,
  onSelectChange: () => {},
  pagination: false,
  dragEnabled: false
};

export default CustomTable;
