import React from 'react';
import { Button } from 'antd';

export function ThemeButton({
  loading = false,
  className = '',

  size = 'medium',
  onClick = () => {},
  disabled = false,
  htmlType = 'button',
  children
}) {
  return (
    <Button
      style={{ minWidth: '90px' }}
      loading={loading}
      size={size}
      className={className}
      htmlType={htmlType}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </Button>
  );
}
