import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getLeaderShipRatingFailure,
  getLeaderShipRatingSuccess,
  getTopLeaderFailure,
  getTopLeaderSuccess,
  getLeadershipSettingsOrganisationSuccess,
  getLeadershipSettingsOrganisationFailure,
  getLeadershipSettingsLeadersSuccess,
  getLeadershipSettingsLeadersFailure,
  getLeadershipLeaderRatingsSettingsSuccess,
  getLeadershipLeaderRatingsSettingsFailure,
  getLeadershipSettingsSuccess,
  getLeadershipSettingsFailure,
  getLeadershipSettingsRequest,
  getAvailableLeadersSuccess,
  getAvailableLeadersFailure
} from './action';
import {
  GET_LEADERSHIP_RATING_REQUEST,
  GET_TOP_LEADER_REQUEST,
  GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST,
  GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST,
  GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST,
  GET_LEADERSHIP_SETTINGS_REQUEST,
  GET_AVAILABLE_LEADERSHIP_REQUEST
} from './actionType';
import {
  getLeaderShipRating,
  getTopLeader,
  getLeadershipSettingsOrganisation,
  getLeadershipSettingsLeaders,
  getLeadershipLeaderRatingsSettings,
  getLeadershipSettings,
  getAvailableLeadersService
} from './services';
import { logOutSuccess } from '../auth/actions';

function* getLeaderShipRatingSaga(action) {
  try {
    const { response } = yield call(getLeaderShipRating, action.payload);
    if (response.status) {
      yield put(getLeaderShipRatingSuccess(response));
    } else {
      yield put(getLeaderShipRatingFailure());
    }
  } catch (error) {
    yield put(logOutSuccess());
    yield put(getLeaderShipRatingFailure());
  }
}
function* getTopLeaderSaga(action) {
  try {
    const { response } = yield call(getTopLeader, action.payload);
    if (response.status) {
      yield put(getTopLeaderSuccess(response?.message));
    } else {
      yield put(getTopLeaderFailure());
    }
  } catch (error) {
    yield put(getTopLeaderFailure());
  }
}
function* getLeadershipSettingsOrganisationSaga(action) {
  try {
    const { response } = yield call(getLeadershipSettingsOrganisation, action.payload);
    if (response.status) {
      yield put(getLeadershipSettingsOrganisationSuccess(response?.message));
    } else {
      yield put(getLeadershipSettingsOrganisationFailure());
    }
  } catch (error) {
    yield put(getLeadershipSettingsOrganisationFailure());
  }
}

function* getLeadershipSettingsLeadersSaga(action) {
  try {
    const { response } = yield call(getLeadershipSettingsLeaders, action.payload);
    if (response.status) {
      yield put(getLeadershipSettingsLeadersSuccess(response));
    } else {
      yield put(getLeadershipSettingsLeadersFailure());
    }
  } catch (error) {
    yield put(getLeadershipSettingsLeadersFailure());
  }
}

function* getLeadershipLeaderRatingsSettingsSaga(action) {
  const { ...rest } = action.payload;
  try {
    const { response } = yield call(getLeadershipLeaderRatingsSettings, rest);
    if (response.status) {
      yield put(getLeadershipLeaderRatingsSettingsSuccess(response));
      // if (deleteData) {
      yield put(
        getLeadershipSettingsRequest({
          user_id: rest.user_id
        })
      );
      // }
    } else {
      yield put(getLeadershipLeaderRatingsSettingsFailure());
    }
  } catch (error) {
    yield put(getLeadershipLeaderRatingsSettingsFailure());
  }
}
function* getLeadershipSettingsSaga(action) {
  try {
    const { response } = yield call(getLeadershipSettings, action.payload);
    if (response.status) {
      yield put(getLeadershipSettingsSuccess(response));
    } else {
      yield put(getLeadershipLeaderRatingsSettingsFailure());
    }
  } catch (error) {
    yield put(getLeadershipSettingsFailure());
  }
}
function* getAvailableLeadersSage(action) {
  try {
    const { response } = yield call(getAvailableLeadersService, action.payload);
    if (response.status) {
      yield put(getAvailableLeadersSuccess(response));
    } else {
      yield put(getLeadershipLeaderRatingsSettingsFailure());
    }
  } catch (error) {
    yield put(getAvailableLeadersFailure());
  }
}

export default function* watchEvent() {
  yield takeLatest(GET_LEADERSHIP_RATING_REQUEST, getLeaderShipRatingSaga);
  yield takeLatest(GET_TOP_LEADER_REQUEST, getTopLeaderSaga);
  yield takeLatest(
    GET_LEADERSHIP_SETTINGS_ORGANISATION_REQUEST,
    getLeadershipSettingsOrganisationSaga
  );
  yield takeLatest(GET_LEADERSHIP_SETTINGS_LEADERS_REQUEST, getLeadershipSettingsLeadersSaga);
  yield takeLatest(
    GET_LEADERSHIP_LEADER_RATINGS_SETTINGS_REQUEST,
    getLeadershipLeaderRatingsSettingsSaga
  );
  yield takeLatest(GET_LEADERSHIP_SETTINGS_REQUEST, getLeadershipSettingsSaga);
  yield takeLatest(GET_AVAILABLE_LEADERSHIP_REQUEST, getAvailableLeadersSage);
}
