import { clearAll, getStorage, hasStorage } from './localStorage';

let initialAuth = {
  verificationData: null,
  authData: null,
  isLogging: false,
  sendingOtp: false
};

const initialModal = {
  modalType: [],
  modalProps: [],
  modalVariant: ''
};

const initialEvent = {
  pastEvents: [],
  futureEvents: [],
  event: {},
  getSingleEventLoading: false,
  getEventLoading: false,
  attendees: [],
  addAttendeeLoading: false,
  getAttendeeLoading: false,
  futureEventPage: 0,
  pastEventPage: 0
};

const initialResource = {
  getAllCategoryLoading: false,
  categories: [],
  addCategoryLoading: false,
  category: {},
  getCategoryLoading: false,
  deleteCategoryLoading: false,
  getAllSubCategoryLoading: false,
  subCategories: [],
  getAllItemLoading: false,
  items: [],
  subCategory: {},
  subItem: {},
  memberList: [],
  registerUpdatedEvent: [],
  getEventLoading: false
};

const initialOrganisation = {
  getOrganisationLoading: false,
  organisationsList: [],
  inactiveorganisationsList: []
};
const initialLeaderShip = {
  leaderShipRatingData: [],
  leaderShipLoading: false,
  topLeader: [],
  leaderRatingLoading: false,
  leadershipOrganisationData: [],
  leadershipOrganisationLoading: false,
  leadershipSettingsTableData: [],
  leaderSettingLoading: false,
  avaialbleLeaders: []
};

if (hasStorage('auth')) {
  initialAuth = {
    ...initialAuth,
    authData: getStorage('auth')
  };
} else {
  clearAll();
}

const initialState = {
  auth: initialAuth,
  modal: initialModal,
  events: initialEvent,
  resources: initialResource,
  organisation: initialOrganisation,
  leaderShip: initialLeaderShip
};

export default initialState;
