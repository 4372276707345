import { call, put, takeLatest } from 'redux-saga/effects';
import openNotification from '../../Components/Notification';
import { hideModal } from '../modal/actions';

import {
  addEventFailure,
  addEventSuccess,
  deleteAttendeeFailure,
  deleteAttendeeSuccess,
  deleteEventFailure,
  deleteEventSuccess,
  editEventFailure,
  editEventSuccess,
  getAllEventsFailure,
  getAllEventsRequest,
  getAllEventsSuccess,
  getAttendeeFailure,
  getAttendeeRequest,
  getAttendeeSuccess,
  getMemberListFailure,
  getMemberListSuccess,
  getSingleEventsFailure,
  getSingleEventsSuccess,
  postAttendeeFailure,
  postAttendeeSuccess,
  registerUpdatedEventFailure,
  registerUpdatedEventSuccess,
  updateEventReducer
} from './actions';
import {
  ADD_EVENT_REQUEST,
  DELETE_ATTENDEE_REQUEST,
  DELETE_EVENT_REQUEST,
  EDIT_EVENT_REQUEST,
  GET_ALL_EVENT_REQUEST,
  GET_ATTENDEE_REQUEST,
  GET_MEMBERS_LIST_REQUEST,
  GET_SINGLE_EVENT_REQUEST,
  POST_ATTENDEE_REQUEST,
  REGISTER_UPDATED_EVENT_REQUEST
} from './actionType';
import {
  postAttendee,
  getAttendee,
  getAllEvents,
  addEvent,
  editEvent,
  deleteEvent,
  getSingleEvents,
  deleteEventAttendee,
  getMembers,
  registerUpdatedEvent
} from './services';

import { logOutSuccess } from '../auth/actions';

function* getAllEventSaga(action) {
  try {
    const { response } = yield call(getAllEvents, action.payload);
    if (response.status === 1 || response.status === 0) {
      if (response.status === 0) {
        openNotification('error', response.message);
        yield put(getAllEventsFailure());
      }
      const result = {
        type: action?.payload?.type
      };
      if (action?.payload?.type === 1) {
        result.futureEvents = response.data?.data;
        result.futureEventPage = action?.payload?.page;
        result.nextPage = response?.data?.next_page_url;
      } else {
        result.pastEvents = response.data?.data;
        result.pastEventPage = action?.payload?.page;
        result.PastnextPage = response?.data?.next_page_url;
      }
      yield put(getAllEventsSuccess(result));
    } else {
      openNotification('error', response.message);
      yield put(logOutSuccess());
      yield put(getAllEventsFailure());
    }
  } catch (error) {
    yield put(getAllEventsFailure());
  }
}

function* getSingleEventSaga(action) {
  try {
    const { response } = yield call(getSingleEvents, action.payload);
    if (response.status) {
      yield put(getSingleEventsSuccess(response.data));
    } else {
      yield put(getSingleEventsFailure());
    }
  } catch (error) {
    yield put(getSingleEventsFailure());
  }
}

function* addEventSaga(action) {
  const { getApiParams, ...rest } = action.payload;
  try {
    const { response } = yield call(addEvent, rest);
    if (response.status) {
      yield put(addEventSuccess());
      yield put(hideModal());
      yield put(getAllEventsRequest(getApiParams));
    } else {
      yield put(addEventFailure());
    }
  } catch (error) {
    yield put(addEventFailure());
  }
}

function* editEventSaga(action) {
  const { getApiParams, ...rest } = action.payload;
  try {
    const { response } = yield call(editEvent, rest);
    if (response.status) {
      yield put(editEventSuccess());
      yield put(hideModal());
      yield put(updateEventReducer({ event: {} }));
      yield put(getAllEventsRequest(getApiParams));
    } else {
      yield put(editEventFailure());
    }
  } catch (error) {
    yield put(editEventFailure());
  }
}

function* deleteEventSaga(action) {
  const { getApiParams, ...rest } = action.payload;
  try {
    const { response } = yield call(deleteEvent, rest);
    if (response.status) {
      yield put(deleteEventSuccess());
      yield put(hideModal());
      yield put(updateEventReducer({ event: {} }));
      yield put(getAllEventsRequest(getApiParams));
    } else {
      yield put(deleteEventFailure());
    }
  } catch (error) {
    yield put(deleteEventFailure());
  }
}

function* postAttendeeSaga(action) {
  try {
    const { response } = yield call(postAttendee, action.payload);
    if (response.status) {
      yield put(postAttendeeSuccess());
    } else {
      yield put(postAttendeeFailure());
    }
  } catch (error) {
    yield put(postAttendeeFailure());
  }
}

function* getAttendeeSaga(action) {
  try {
    const { response } = yield call(getAttendee, action.payload);
    if (response.status) {
      yield put(getAttendeeSuccess());
    } else {
      yield put(getAttendeeFailure());
    }
  } catch (error) {
    yield put(getAttendeeFailure());
  }
}

function* deleteAttendeeSaga(action) {
  const { getApiParams, ...rest } = action.payload;
  try {
    const { response } = yield call(deleteEventAttendee, rest);
    if (response.status) {
      yield put(deleteAttendeeSuccess());
      yield put(hideModal());
      yield put(getAllEventsRequest(getApiParams));
      yield put(getAttendeeRequest(getApiParams));
    } else {
      yield put(deleteAttendeeFailure());
    }
  } catch (error) {
    yield put(deleteAttendeeFailure());
  }
}
function* getMembersSaga(action) {
  try {
    const { response } = yield call(getMembers, action.payload);
    if (response) {
      yield put(getMemberListSuccess(response));
    } else {
      yield put(getMemberListFailure());
    }
  } catch (error) {
    yield put(getMemberListFailure());
  }
}
function* registerUpdatedEventSaga(action) {
  const { getApiParams, ...rest } = action.payload;
  try {
    const { response } = yield call(registerUpdatedEvent, rest);
    if (response) {
      yield put(registerUpdatedEventSuccess(response));
      yield put(getAllEventsRequest(getApiParams));
      openNotification('success', 'Registration successfully');
    } else {
      yield put(registerUpdatedEventFailure());
      openNotification('error', 'Registration failed');
    }
  } catch (error) {
    yield put(registerUpdatedEventFailure());
    openNotification('error', 'Registration failed');
  }
}

export default function* watchEvent() {
  yield takeLatest(GET_ALL_EVENT_REQUEST, getAllEventSaga);
  yield takeLatest(GET_SINGLE_EVENT_REQUEST, getSingleEventSaga);
  yield takeLatest(ADD_EVENT_REQUEST, addEventSaga);
  yield takeLatest(EDIT_EVENT_REQUEST, editEventSaga);
  yield takeLatest(DELETE_EVENT_REQUEST, deleteEventSaga);
  yield takeLatest(POST_ATTENDEE_REQUEST, postAttendeeSaga);
  yield takeLatest(GET_ATTENDEE_REQUEST, getAttendeeSaga);
  yield takeLatest(DELETE_ATTENDEE_REQUEST, deleteAttendeeSaga);
  yield takeLatest(GET_MEMBERS_LIST_REQUEST, getMembersSaga);
  yield takeLatest(REGISTER_UPDATED_EVENT_REQUEST, registerUpdatedEventSaga);
}
