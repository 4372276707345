import * as types from './actionType';

export const getAllOrganisationsRequest = (data) => ({
  type: types.GET_ALL_ORGANISATION_REQUEST,
  payload: data
});

export const getAllOrganisationsSuccess = (data) => ({
  type: types.GET_ALL_ORGANISATION_SUCCESS,
  payload: data
});

export const getAllOrganisationsFailure = (data) => ({
  type: types.GET_ALL_ORGANISATION_FAILURE,
  payload: data
});

export const createOrganisationsRequest = (data) => ({
  type: types.CREATE_ORGANISATION_REQUEST,
  payload: data
});

export const createOrganisationsSuccess = (data) => ({
  type: types.CREATE_ORGANISATION_SUCCESS,
  payload: data
});

export const createOrganisationsFailure = (data) => ({
  type: types.CREATE_ORGANISATION_FAILURE,
  payload: data
});

export const postStatusUpdateRequest = (data) => ({
  type: types.POST_STATUS_UPDATE_REQUEST,
  payload: data
});
export const postStatusUpdateSuccess = (data) => ({
  type: types.POST_STATUS_UPDATE_SUCCESS,
  payload: data
});
export const postStatusUpdateFailure = (data) => ({
  type: types.POST_STATUS_UPDATE_FAILURE,
  payload: data
});
export const postWebsiteUpdateRequest = (data) => ({
  type: types.POST_WEBSITE_UPDATE_REQUEST,
  payload: data
});

export const postWebsiteUpdateSuccess = (data) => ({
  type: types.POST_WEBSITE_UPDATE_SUCCESS,
  payload: data
});

export const postWebsiteUpdateFailure = (data) => ({
  type: types.POST_WEBSITE_UPDATE_FAILURE,
  payload: data
});
