import React from 'react';
import SearchContainer from '../../Components/SearchContainer';
import LeaderShipContainer from './LeaderShipContainer';

export const LeaderShipPairing = () => {
  return (
    <div>
      <SearchContainer title="Leadership Pairing" />
      <div className="d-flex flex-column p-3 container leadership-wrapper" id="leaderList">
        <LeaderShipContainer />
      </div>
    </div>
  );
};
export default LeaderShipPairing;
