import { fork } from 'redux-saga/effects';

import authSagas from './auth/saga';
import evenSagas from './events/saga';
import resourceSagas from './resources/saga';
import organisationSagas from './organisations/saga';
import leaderShipSagas from './leadership/saga';

function* rootSaga() {
  yield fork(authSagas);
  yield fork(evenSagas);
  yield fork(resourceSagas);
  yield fork(organisationSagas);
  yield fork(leaderShipSagas);
}

export default rootSaga;
