export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const DELETE_ALERT = 'DELETE_ALERT';

export const ADD_EVENT = 'ADD_EVENT';
export const ADD_ATTENDEE = 'ADD_ATTENDEE';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const LEADERSHIP_LEADER_BOARD = 'LEADERSHIP_LEADER_BOARD';
export const LEADERSHIP_PAIRING_SETTINGS = 'LEADERSHIP_PAIRING_SETTINGS';
export const ADD_FAQ = 'ADD_FAQ';
export const SUPPORT_NEW_CATEGORY = 'SUPPORT_NEW_CATEGORY';
export const SUPPORT_NEW_TUTORIAL = 'SUPPORT_NEW_TUTORIAL';
export const EDIT_QUICK_INFO = 'EDIT_QUICK_INFO';
