import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import PublicRoute from './routeGuard/PublicRoute';
import PrivateRoute from './routeGuard/PrivateRoute';
import { BASE_PATH_PREFIX, LOGIN_PATH } from './Constants/routes';

const RouteComp = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path={'/*'} element={<PrivateRoute path={BASE_PATH_PREFIX} />} />
            <Route path={LOGIN_PATH} element={<PublicRoute />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default RouteComp;
