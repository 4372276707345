import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { QuickSearch } from '../../Components/FormFields/QuickSearch';
import CustomTable from '../../Components/Table';
import { dynamicColorChanger } from '../../Constants/utils';
import { get } from '../../services/request';
import CustomIcon from '../../Constants/Icons';

const formInitial = {
  employment: { completed: false, title: 'Employment', steps: 24 },
  insurance: { completed: false, title: 'Insurance', steps: 8 },
  policies: { completed: false, title: 'Policies and Procedures', steps: 17 },
  financial: { completed: false, title: 'Financial Report', steps: 2 },
  premises: { completed: false, title: 'Premises', steps: 2 },
  operations: { completed: false, title: 'Operations', steps: 13 },
  audit: { completed: false, title: 'Audit Report', steps: 1 }
};

const filesName = {
  liability_file: 'Public Liability Policy',
  indemnity_file: 'Professional Indemnity Policy',
  management_file: 'Management Liability Policy',
  workerCompensation_file: 'Work Compensation Policy',
  fire_file: 'Fire Policy',
  vehicle_file: 'Comprehensive Motor Vehicle Policy',
  cash_file: 'Cash Policy',
  cyber_file: 'Cyber Insurance Policy',
  atoCopy_file: 'Australian Taxation Office',
  plsCopy_file: 'Profit and Loss Statement',
  auditorReportCopy_file: 'Audit of Trust Accounts'
};

const YearSelector = ({ year, onChange }) => (
  <div className="d-flex">
    <div style={{ alignSelf: 'center' }}>
      <span className="direction-icon " onClick={() => onChange(year - 1)}>
        <CustomIcon icon="left" className="" />
      </span>
    </div>
    <div
      className="d-flex justify-content-center align-items-center contentHover"
      style={{ minWidth: '100px' }}>
      <span className="ps-2">{year}</span>
    </div>
    <div style={{ alignSelf: 'center' }}>
      <span
        className={`${year === new Date().getFullYear() ? 'disableButton' : ''} direction-icon`}
        onClick={() => onChange(year + 1)}>
        <CustomIcon icon="right" className="" />
      </span>
    </div>
  </div>
);

const getTotalKeys = (obj) => {
  return Object.keys(obj).length;
};

export const FranchiseReportContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [quickSearchTerm, setQuickSearchTerm] = useState('');
  const [searchYear, setSearchYear] = useState(new Date().getFullYear());

  let reportsFiltered = reports;

  if (quickSearchTerm) {
    reportsFiltered = reports.filter((report) => {
      return (
        String(report.organisation_name).toLowerCase().indexOf(quickSearchTerm.toLowerCase()) !== -1
      );
    });
  }

  reportsFiltered.sort((a, b) => a.organisation_name.localeCompare(b.organisation_name));

  useEffect(() => {
    setIsLoading(true);
    get(`resources/get-reports?year=${searchYear}`, { wolEndpoint: 1, year: searchYear })
      .then(({ response }) => {
        setIsLoading(false);
        setReports(response.data);
      })
      .catch((err) => {
        console.error('error loading reports', err);
        setIsLoading(false);
      });
  }, [searchYear]);

  const countCompletedSteps = (formData) => {
    let total = 0;

    Object.keys(formData).forEach((key) => {
      const numberOfKeys = getTotalKeys(formData[key]);
      if (numberOfKeys && formInitial[key] && numberOfKeys >= formInitial[key].steps) {
        total++;
      }
    });

    return total;
  };

  const columns = [
    {
      title: 'Office',
      dataIndex: 'organisation_name',
      key: 'organisation_name',
      render: (data) => {
        return <p style={{ textAlign: 'left', margin: '0px' }}>{data}</p>;
      }
    },
    {
      title: 'Progress',
      dataIndex: 'data',
      key: 'data',
      render: (title, data) => {
        const formData = JSON.parse(data.data);
        const completedSteps = countCompletedSteps(formData);
        const finalResult =
          completedSteps === 7 ? 'Passed' : completedSteps <= 2 ? 'Failed' : 'Pending';

        return (
          <div
            key={data.id}
            className="font-weight-bold"
            style={{ color: `${dynamicColorChanger(finalResult)}` }}>
            {finalResult === 'Passed' ? (
              <a
                href="#"
                target="_blank"
                style={{ textDecoration: 'none', color: 'green' }}
                rel="noopener noreferrer">
                Submitted
              </a>
            ) : (
              `${Math.floor((completedSteps / 7) * 100)}%`
            )}
          </div>
        );
      }
    },
    {
      title: 'Files',
      dataIndex: 'data',
      key: 'files',
      render: (title, data) => {
        const formData = JSON.parse(data.data);
        const pdfURL = data.report_pdf;
        const reportFiles = [];

        Object.entries(formData).forEach((element) =>
          Object.entries(element[1]).forEach((child) => {
            if (child[0].indexOf('_file') !== -1 && typeof child[1] === 'string') {
              reportFiles.push({ name: child[0], value: child[1] });
            }
          })
        );

        if (pdfURL !== null) reportFiles.push({ name: 'report', value: pdfURL });

        return (
          <div>
            {reportFiles.length ? (
              reportFiles.map((file) => (
                <span
                  key={file.name}
                  className="badge bg-primary"
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                  onClick={() => window.open(file.value)}>
                  {file.name === 'report' ? 'Full Report' : filesName[file.name]}
                </span>
              ))
            ) : (
              <span className="badge bg-danger">No files</span>
            )}
          </div>
        );
      }
    }
  ];

  if (isLoading) return <p className="text-center">Loading reports</p>;

  return (
    <div>
      <div className="d-flex justify-content-between pt-3">
        <YearSelector year={searchYear} onChange={setSearchYear} />
        <div>
          <QuickSearch
            value={quickSearchTerm}
            onChange={(e) => setQuickSearchTerm(e.target.value)}
            showDic={false}
          />
        </div>
      </div>
      <div className="pt-3">
        {reportsFiltered.length ? (
          <CustomTable columns={columns} dataSource={reportsFiltered} scroll={{ y: 500 }} />
        ) : (
          <p>No reports found</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(FranchiseReportContainer);
