export const UPDATE_REOURCE_REDUCER = 'UPDATE_REOURCE_REDUCER';

export const GET_ALL_CATEGORIES_REQUEST = 'GET_ALL_CATEGORIES_REQUEST';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAILURE = 'GET_ALL_CATEGORIES_FAILURE';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const GET_SINGLE_CATEGORY_REQUEST = 'GET_SINGLE_CATEGORY_REQUEST';
export const GET_SINGLE_CATEGORY_SUCCESS = 'GET_SINGLE_CATEGORY_SUCCESS';
export const GET_SINGLE_CATEGORY_FAILURE = 'GET_SINGLE_CATEGORY_FAILURE';

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const GET_ALL_SUB_CATEGORIES_REQUEST = 'GET_ALL_SUB_CATEGORIES_REQUEST';
export const GET_ALL_SUB_CATEGORIES_SUCCESS = 'GET_ALL_SUB_CATEGORIES_SUCCESS';
export const GET_ALL_SUB_CATEGORIES_FAILURE = 'GET_ALL_SUB_CATEGORIES_FAILURE';

export const ADD_SUB_CATEGORY_REQUEST = 'ADD_SUB_CATEGORY_REQUEST';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_FAILURE = 'ADD_SUB_CATEGORY_FAILURE';

export const GET_SINGLE_SUB_CATEGORY_REQUEST = 'GET_SINGLE_SUB_CATEGORY_REQUEST';
export const GET_SINGLE_SUB_CATEGORY_SUCCESS = 'GET_SINGLE_SUB_CATEGORY_SUCCESS';
export const GET_SINGLE_SUB_CATEGORY_FAILURE = 'GET_SINGLE_SUB_CATEGORY_FAILURE';

export const EDIT_SUB_CATEGORY_REQUEST = 'EDIT_SUB_CATEGORY_REQUEST';
export const EDIT_SUB_CATEGORY_SUCCESS = 'EDIT_SUB_CATEGORY_SUCCESS';
export const EDIT_SUB_CATEGORY_FAILURE = 'EDIT_SUB_CATEGORY_FAILURE';

export const DELETE_SUB_CATEGORY_REQUEST = 'DELETE_SUB_CATEGORY_REQUEST';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_FAILURE = 'DELETE_SUB_CATEGORY_FAILURE';

export const GET_ALL_ITEMS_REQUEST = 'GET_ALL_ITEMS_REQUEST';
export const GET_ALL_ITEMS_SUCCESS = 'GET_ALL_ITEMS_SUCCESS';
export const GET_ALL_ITEMS_FAILURE = 'GET_ALL_ITEMS_FAILURE';

export const ADD_ITEM_REQUEST = 'ADD_ITEM_REQUEST';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILURE = 'ADD_ITEM_FAILURE';

export const GET_SINGLE_ITEM_REQUEST = 'GET_SINGLE_ITEM_REQUEST';
export const GET_SINGLE_ITEM_SUCCESS = 'GET_SINGLE_ITEM_SUCCESS';
export const GET_SINGLE_ITEM_FAILURE = 'GET_SINGLE_ITEM_FAILURE';

export const EDIT_ITEM_REQUEST = 'EDIT_ITEM_REQUEST';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILURE = 'EDIT_ITEM_FAILURE';

export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
