import React, { useEffect, useState } from 'react';
import moment from 'moment';

import CustomIcon from '../../Constants/Icons';

export const DynamicMonthChanger = ({
  month = false,
  monthChanged = () => {},
  diableButtons = false,
  editIconEnable = () => {}
}) => {
  const [selectedMonth, setSelectedMonth] = useState({});
  useEffect(() => {
    let currentDate = moment(Date.now());
    let month = currentDate.format('MMMM');
    let currentYear = currentDate.format('YYYY');
    setSelectedMonth({ momentMonth: currentDate, monthDetail: month, year: currentYear });
  }, []);
  useEffect(() => {
    monthChanged(selectedMonth);
  }, [selectedMonth]);
  const monthChanger = (direction) => {
    editIconEnable(false);
    let monthAbbr;
    let changedMonth;
    let changedYear;
    if (direction === 'right') {
      if (month) {
        changedMonth = moment(selectedMonth.momentMonth).add(1, 'M');
        monthAbbr = changedMonth.format('MMMM');
        changedYear = changedMonth.format('YYYY');
      } else {
        changedMonth = moment(selectedMonth.momentMonth).add(1, 'Y');
        changedYear = changedMonth.format('YYYY');
      }
    } else {
      if (month) {
        changedMonth = moment(selectedMonth.momentMonth).subtract(1, 'M');
        monthAbbr = changedMonth.format('MMMM');
        changedYear = changedMonth.format('YYYY');
      } else {
        changedMonth = moment(selectedMonth.momentMonth).subtract(1, 'Y');
        changedYear = changedMonth.format('YYYY');
      }
    }
    setSelectedMonth({
      momentMonth: changedMonth,
      monthDetail: monthAbbr,
      year: changedYear
    });
  };
  return (
    <div>
      <div className="d-flex">
        <div>
          <span
            className="direction-icon "
            onClick={() => {
              monthChanger('left');
            }}>
            <CustomIcon icon="left" className="" />
          </span>
        </div>
        <div
          className="d-flex justify-content-center align-items-center contentHover"
          style={{ fontSize: '14px', minWidth: '130px' }}>
          {month ? <span className="font-weight-bold">{selectedMonth?.monthDetail}</span> : ''}
          <span className="ps-2">{selectedMonth?.year}</span>
        </div>
        <div>
          <span
            className={`${diableButtons ? 'disableButton' : ''} direction-icon`}
            onClick={() => {
              monthChanger('right');
            }}>
            <CustomIcon icon="right" className="" />
          </span>
        </div>
      </div>
    </div>
  );
};
