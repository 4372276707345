import * as types from './actionType';

function eventReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case types.UPDATE_EVENT_REDUCER:
      return {
        ...state,
        ...payload
      };

    case types.GET_ALL_EVENT_REQUEST:
      return {
        ...state,
        getEventLoading: true
      };
    case types.GET_ALL_EVENT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { type, ...rest } = payload;
      // eslint-disable-next-line no-case-declarations
      const tempPayload = { ...rest };
      if (type === 1) {
        tempPayload.futureEvents =
          tempPayload.futureEventPage === 1
            ? tempPayload.futureEvents
            : [...state.futureEvents, ...tempPayload.futureEvents];
      } else {
        tempPayload.pastEvents =
          tempPayload.pastEventPage === 1
            ? tempPayload.pastEvents
            : [...state.pastEvents, ...tempPayload.pastEvents];
      }
      return {
        ...state,
        getEventLoading: false,
        ...tempPayload
      };
    case types.GET_ALL_EVENT_FAILURE:
      return {
        ...state,
        getEventLoading: false
      };

    case types.GET_SINGLE_EVENT_REQUEST:
      return {
        ...state,
        getSingleEventLoading: true
      };
    case types.GET_SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        getSingleEventLoading: false,
        event: payload
      };
    case types.GET_SINGLE_EVENT_FAILURE:
      return {
        ...state,
        getSingleEventLoading: false
      };

    case types.ADD_EVENT_REQUEST:
      return {
        ...state,
        saveEventLoading: true
      };
    case types.ADD_EVENT_SUCCESS:
      return {
        ...state,
        saveEventLoading: false
      };
    case types.ADD_EVENT_FAILURE:
      return {
        ...state,
        saveEventLoading: false
      };

    case types.EDIT_EVENT_REQUEST:
      return {
        ...state,
        saveEventLoading: true
      };
    case types.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        saveEventLoading: false
      };
    case types.EDIT_EVENT_FAILURE:
      return {
        ...state,
        saveEventLoading: false
      };

    case types.DELETE_EVENT_REQUEST:
      return {
        ...state,
        deleteEventLoading: true
      };
    case types.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deleteEventLoading: false
      };
    case types.DELETE_EVENT_FAILURE:
      return {
        ...state,
        deleteEventLoading: false
      };

    case types.POST_ATTENDEE_REQUEST:
      return {
        ...state,
        addAttendeeLoading: true
      };
    case types.POST_ATTENDEE_SUCCESS:
      return {
        ...state,
        addAttendeeLoading: false
      };
    case types.POST_ATTENDEE_FAILURE:
      return {
        ...state,
        addAttendeeLoading: false
      };

    case types.GET_ATTENDEE_REQUEST:
      return {
        ...state,
        getAttendeeLoading: true
      };
    case types.GET_ATTENDEE_SUCCESS:
      return {
        ...state,
        getAttendeeLoading: false
      };
    case types.GET_ATTENDEE_FAILURE:
      return {
        ...state,
        getAttendeeLoading: false
      };

    case types.DELETE_ATTENDEE_REQUEST:
      return {
        ...state,
        deleteAttendeeLoading: true
      };
    case types.DELETE_ATTENDEE_SUCCESS:
      return {
        ...state,
        deleteAttendeeLoading: false
      };
    case types.DELETE_ATTENDEE_FAILURE:
      return {
        ...state,
        deleteAttendeeLoading: false
      };
    case types.GET_MEMBERS_LIST_REQUEST:
      return {
        ...state,
        memberList: []
        // getEventLoading: true
      };
    case types.GET_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        memberList: payload.data
        // getEventLoading: false
      };
    case types.GET_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        memberList: []
        // getEventLoading: false
      };
    case types.REGISTER_UPDATED_EVENT_REQUEST:
      return {
        ...state,
        getEventLoading: true
      };
    case types.REGISTER_UPDATED_EVENT_SUCCESS:
      return {
        ...state,
        getEventLoading: false,
        registerUpdatedEvent: payload
      };
    case types.REGISTER_UPDATED_EVENT_FAILURE:
      return {
        ...state,
        getEventLoading: false,
        registerUpdatedEvent: []
      };
    default:
      return state;
  }
}

export default eventReducer;
